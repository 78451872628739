<template>
  <div class="login-sign-up">
    <LogoText class="login-sign-up__logo-text" />
    <h1 v-show="navigationText" class="login-sign-up__navigation-text">{{ navigationText }}</h1>
    <div class="login-sign-up__form">
      <div class="form__img">
        <LogoImage class="form__logo-image" />
      </div>
      <div class="form__container">
        <h1 v-show="navigationText" class="login-sign-up__navigation-text login-sign-up__navigation-text--desktop">
          {{ navigationText }}
        </h1>
        <p class="form__caption">Practica para tu exámen y alcanza tus metas</p>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import LogoImage from "@/assets/logo.svg?inline";
import LogoText from "@/components/logo_text/LogoText.vue";

export default {
  name: "EnterPage",
  components: {
    LogoText,
    LogoImage,
  },
  computed: {
    navigationText() {
      const { name } = this.$route;
      if (name === "LoginPage") {
        return "Iniciar Sesión";
      }
      if (name === "SignUpPage") {
        return "Registrarse";
      }
      return "";
    },
  },
  mounted() {
    this.$emit("load-finished");
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/colors";
@import "~@/scss/_typography.scss";

/** @define login-sign-up */
.login-sign-up {
  color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $blue;
  background-image: url("~@/assets/watermark.svg");
  background-repeat: repeat-x;
  background-position: bottom;
  height: 100%;

  @include laptop {
    background-size: 35%;
  }
}

.login-sign-up__logo-text {
  display: none;

  @include laptop {
    display: flex;
    padding-left: 6em;
    padding-top: 2em;
  }
}

.login-sign-up__navigation-text {
  @extend %font-section-title-1;

  display: flex;
  padding-left: 1em;
  justify-content: left;
  text-decoration: underline;
  @include laptop {
    display: none;
  }
}

.login-sign-up__navigation-text--desktop {
  @extend %font-section-title-1;

  display: none;
  justify-content: center;
  @include laptop {
    display: flex;
  }
}

.login-sign-up__form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  @include laptop {
    flex-direction: row;
  }
}

/** @define form */
.form__img {
  width: 100%;
  display: flex;
  flex-grow: 2;
  justify-content: center;

  @include laptop {
    flex-grow: 1;
  }
}

.form__logo-image {
  width: 18em;
  @include laptop {
    width: 35%;
  }
}

.form__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 3;

  @include laptop {
    flex-grow: 1;
  }
}

.form__caption {
  @extend %font-subtitle-1;

  display: none;
  text-align: center;
  @include laptop {
    display: block;
  }
}
</style>
