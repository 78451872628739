<template>
  <div class="hints-box">
    <HintsImage v-if="hintIndex === 0" class="hints-box__img" data-test-id="hints-box-image"></HintsImage>

    <div v-for="index in hintIndex" :key="index" class="hints-box__text-container" data-test-id="hint-content">
      <span class="hints-box__numeration"> ({{ index }}/{{ exerciseHints.length }}) </span>
      {{ " " }} {{ exerciseHints[index - 1].text }}
    </div>

    <BaseButton
      style-modifier="text"
      class="hints-box__add-hint-btn"
      :is-disabled="isAddHintButtonDisabled"
      data-test-id="get-next-hint-button"
      @click="getNextHint"
    >
      + Obtener pista por 10
      <CoinsImage :class="'add-hint-btn__img ' + coinsImageModifierClass" />
    </BaseButton>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import HintsImage from "@/assets/hints.svg?inline";
// eslint-disable-next-line import/extensions
import CoinsImage from "@/assets/coins.svg?inline";
import BaseButton from "@/components/base_button/BaseButton.vue";

export default {
  name: "ExerciseHintsSidebarBox",
  components: {
    HintsImage,
    CoinsImage,
    BaseButton,
  },
  props: {
    exerciseHints: {
      type: Array,
      default: null,
    },
    lastHintIndexToLoad: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hintIndex: 0,
    };
  },
  computed: {
    coinsImageModifierClass() {
      return this.isAddHintButtonDisabled ? "add-hint-btn__img--disabled" : "";
    },
    isAddHintButtonDisabled() {
      return this.lastHintIndexToLoad >= this.exerciseHints.length;
    },
  },
  watch: {
    lastHintIndexToLoad() {
      this.hintIndex = this.lastHintIndexToLoad;
    },
  },
  methods: {
    created() {
      this.hintIndex = this.lastHintIndexToLoad;
    },
    getNextHint() {
      if (this.hintIndex < this.exerciseHints.length) {
        this.hintIndex += 1;
        this.$emit("button-click", this.hintIndex);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";
@import "~@/scss/_fonts.scss";

/** @define hints-box; */
.hints-box {
  font-size: map-get($font-base, size);
  width: 80%;
  border-radius: 25px;
  border: 0.3em solid $secondary-color;
  display: flex;
  padding: 1.5em;
  flex-direction: column;
  align-items: center;
}

.hints-box__text-container {
  @extend %font-body-2;

  padding: 0.5em 0;
}

.hints-box__img {
  width: 20%;
  padding-bottom: 1em;
}

.hints-box__add-hint-btn {
  color: $primary-color;
}

.hints-box__numeration {
  @extend %font-caption-3;

  color: $secondary-color;
}

/** @define add-hint-btn; weak */
.add-hint-btn__img {
  width: 1.5em;
}

.add-hint-btn__img--disabled > path {
  fill: $disabled-color;
}
</style>
