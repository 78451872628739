<template>
  <div>
    <div class="login-form">
      <div class="login-form__field">
        <BaseInputPassword
          v-model="password"
          :is-text-hidden="!isPasswordVisible"
          :is-invalid-password-message-visible="isInvalidPasswordMessageVisible"
          error-message="Contraseña incorrecta"
          @keyup.enter.native="login()"
        />
        <BaseInputCheckbox v-model="isPasswordVisible" class="field__input-check">
          Mostrar contraseña
        </BaseInputCheckbox>
      </div>

      <div class="login-form__field">
        <BaseButton style-modifier="secondary" @click="login()">
          Continuar
        </BaseButton>
      </div>
    </div>

    <EmailRegisteredWithSocialAccountModal
      v-model="isEmailRegisteredWithSocialAccountModalVisible"
      data-test-id="email-registered-with-social-account-modal"
      :auth-provider="socialAuthProvider"
    />
  </div>
</template>

<script>
import localForage from "localforage";
import BaseButton from "@/components/base_button/BaseButton.vue";
import BaseInputCheckbox from "@/components/base_input_checkbox/BaseInputCheckbox.vue";
import Login from "@/use_cases/login_sign_up/Login";
import InvalidPassword from "@/exceptions/InvalidPassword";
// eslint-disable-next-line max-len
import EmailRegisteredWithSocialAccountModal from "@/components/email_registered_with_social_account_modal/EmailRegisteredWithSocialAccountModal.vue";
import AccountRegisteredWithDifferentAuthProvider from "@/exceptions/AccountRegisteredWithDifferentAuthProvider";
import BaseInputPassword from "@/components/base_input_password/BaseInputPassword.vue";

export default {
  name: "LoginPage",
  components: {
    BaseInputPassword,
    BaseButton,
    BaseInputCheckbox,
    EmailRegisteredWithSocialAccountModal,
  },
  data() {
    return {
      password: "",
      isPasswordVisible: false,
      loginUseCaseResponse: "",
      isInvalidPasswordMessageVisible: false,
      isEmailRegisteredWithSocialAccountModalVisible: false,
      socialAuthProvider: "facebook",
    };
  },
  methods: {
    async login() {
      try {
        this.isInvalidPasswordMessageVisible = false;
        this.loginUseCaseResponse = await Login.login({
          password: this.password,
        });

        await localForage.setItem(
          "isStudentRegistrationPending",
          this.loginUseCaseResponse.isStudentRegistrationPending
        );

        if (this.loginUseCaseResponse.isStudentRegistrationPending) {
          await this.$router.push({ name: "DemoPage" });
        } else {
          await this.$router.push({ name: "Home" });
        }
      } catch (e) {
        if (e instanceof InvalidPassword) {
          this.isInvalidPasswordMessageVisible = true;
        }
        if (e instanceof AccountRegisteredWithDifferentAuthProvider) {
          this.showEmailRegisteredWithSocialAccountModal(e.authProvider);
        }
      }
    },

    showEmailRegisteredWithSocialAccountModal(authProvider) {
      this.socialAuthProvider = authProvider;
      this.isEmailRegisteredWithSocialAccountModalVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
/** @define login-form */
.login-form {
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
  width: 80%;
}

.login-form__field {
  flex: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 0.6em;
}

/** @define field */
.field__input-check {
  padding-top: 1em;
  padding-bottom: 1em;
}
</style>
