<template>
  <div class="exercise-hints">
    <div :class="'exercise-hints__collapse-btn-container ' + animationClass">
      <BaseButton
        style-modifier="close-hint"
        :class="isHintsContainerVisible ? 'exercise-hints__collapse-btn' : 'exercise-hints__expand-btn'"
        @click="toggleHintsContainerVisibility"
      >
        <CollapseArrowImage :class="isHintsContainerVisible ? 'collapse-btn-animation' : 'expand-btn-animation'" />
      </BaseButton>
    </div>

    <div :class="'exercise-hints__hints-container-collapsed ' + animationClass" />

    <VerticalSlide
      name="slide-top"
      @enter="transitionHintsContainerEnter"
      @after-enter="transitionHintsContainerAfterEnter"
      @leave="transitionHintsContainerLeave"
      @after-leave="transitionHintsContainerAfterLeave"
    >
      <div v-show="isHintsContainerVisible" class="exercise-hints__hints-container">
        <div v-for="index in hintIndex" :key="index" class="exercise-hints__text-container">
          <span class="exercise-hints__numeration"> ({{ index }}/{{ exerciseHints.length }}) </span>
          {{ " " }} {{ exerciseHints[index - 1].text }}
        </div>

        <BaseButton
          style-modifier="text"
          class="exercise-hints__add-hint-btn"
          :is-disabled="isAddHintButtonDisabled"
          @click="getNextHint"
        >
          + Obtener pista por 10
          <CoinsImage :class="'add-hint-btn__img ' + coinsImageModifierClass" />
        </BaseButton>
      </div>
    </VerticalSlide>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
// eslint-disable-next-line import/extensions
import CollapseArrowImage from "@/assets/collapse-arrow.svg?inline";
// eslint-disable-next-line import/extensions
import CoinsImage from "@/assets/coins.svg?inline";
import VerticalSlide from "@/transitions/VerticalSlide.vue";

export default {
  name: "ExerciseHintsBottomSheet",
  components: {
    BaseButton,
    CollapseArrowImage,
    CoinsImage,
    VerticalSlide,
  },
  props: {
    exerciseHints: {
      type: Array,
      required: true,
    },
    lastHintIndexToLoad: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isHintsContainerVisible: true,
      animationClass: "",
      hintIndex: 0,
    };
  },

  computed: {
    isAddHintButtonDisabled() {
      return this.hintIndex >= this.exerciseHints.length;
    },
    coinsImageModifierClass() {
      return this.isAddHintButtonDisabled ? "add-hint-btn__img--disabled" : "";
    },
  },
  watch: {
    lastHintIndexToLoad() {
      this.hintIndex = this.lastHintIndexToLoad;
    },
  },
  methods: {
    created() {
      this.hintIndex = this.lastHintIndexToLoad;
    },
    getNextHint() {
      if (this.hintIndex < this.exerciseHints.length) {
        this.hintIndex += 1;
      }
      this.$emit("button-click", this.hintIndex);
    },
    toggleHintsContainerVisibility() {
      this.isHintsContainerVisible = !this.isHintsContainerVisible;
    },
    transitionHintsContainerEnter(appliedAnimationClass) {
      this.animationClass = appliedAnimationClass;
    },
    transitionHintsContainerAfterEnter() {
      this.animationClass = "";
    },
    transitionHintsContainerLeave(appliedAnimationClass) {
      this.animationClass = appliedAnimationClass;
    },
    transitionHintsContainerAfterLeave() {
      this.animationClass = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define exercise-hints */
.exercise-hints {
  display: flex;
  flex-direction: column;
  color: $white;
  width: 100%;
}

.exercise-hints__collapse-btn-container {
  background: $primary-color;
  align-self: flex-end;
  width: 1.5em;
  padding: 0 0.5em;
  margin-right: 1.2em;
}

.exercise-hints__collapse-btn,
.exercise-hints__expand-btn {
  ::v-deep > .base-btn__btn {
    padding: 5px 0 0;
  }
}

.exercise-hints__hints-container {
  background: $primary-color;
  padding: 0.5em 3.5em 3.5em;
}

.exercise-hints__hints-container-collapsed {
  background: $primary-color;
  height: 1em;
}

.exercise-hints__text-container {
  @extend %font-body-2;

  padding: 0.5em 0;
}

.exercise-hints__numeration {
  @extend %font-caption-3;

  color: $secondary-color;
}

.exercise-hints__add-hint-btn {
  display: flex;
  justify-content: flex-end;
  color: $secondary-color;
}

/** @define add-hint-btn; weak */
.add-hint-btn__img {
  width: 1.5em;
}

.add-hint-btn__img--disabled > path {
  fill: $disabled-color;
}

/** @define expand-btn-animation */
.expand-btn-animation {
  animation: rotate-180-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes rotate-180-cw {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

/** @define collapse-btn-animation */
.collapse-btn-animation {
  animation: rotate-180-ccw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes rotate-180-ccw {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0);
  }
}
</style>
