<template>
  <div class="answer-sheet-item">
    <BaseContainer>
      <BaseButton
        v-show="isPreviousButtonVisible"
        style-modifier="previous-item"
        data-test-id="previous-button"
        class="answer-sheet-item__previous-btn"
        @click="loadPreviousAnswerSheetItem"
      >
        <PreviousButtonArrowImage class="previous-btn__img" />
        Anterior
      </BaseButton>

      <ExamExercise
        v-model="localSelectedAnswerId"
        :exercise="exercise"
        exercise-image-position="afterText"
        :exercise-number="answerSheetItemNumber"
        :answers="answers"
        :is-selected-answer-correct="isSelectedAnswerCorrect"
        :are-answers-disabled="false"
        class="answer-sheet-item__exercise"
        data-test-id="answer-sheet-item-exercise"
      >
        <div v-if="exercise.imageUrl !== ''" slot="exerciseImage">
          <img :src="exercise.imageUrl" alt="exercise image" />
        </div>
      </ExamExercise>

      <div class="answer-sheet-item__evaluate-btn">
        <BaseButton
          style-modifier="secondary"
          class="evaluate-btn__btn"
          :is-disabled="selectedAnswerId === null"
          data-test-id="next-button"
          @click="loadNextAnswerSheetItem"
        >
          {{ nextButtonText }}
        </BaseButton>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import ExamExercise from "@/components/exam_exercise/ExamExercise.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import BaseContainer from "@/components/BaseContainer.vue";
// eslint-disable-next-line import/extensions
import PreviousButtonArrowImage from "@/assets/collapse-arrow.svg?inline";

export default {
  name: "AnswerSheetItem",
  components: {
    BaseContainer,
    BaseButton,
    ExamExercise,
    PreviousButtonArrowImage,
  },
  model: {
    prop: "selectedAnswerId",
    event: "answer-selected",
  },
  props: {
    answerSheetId: {
      type: String,
      required: true,
    },
    answerSheetItemId: {
      type: String,
      required: true,
    },
    /** @type {{ text: string, imageUrl: string }} * */
    exercise: { type: Object, required: true },
    /** @type {{ id: number, text: string, imageUrl: string } []} * */
    answers: { type: Array, required: true },
    answerSheetItemNumber: { type: Number, required: true },
    previousAnswerSheetItemId: { type: String, default: null },
    nextAnswerSheetItemId: { type: String, default: null },
    selectedAnswerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isSelectedAnswerCorrect: null,
    };
  },
  computed: {
    nextButtonText() {
      return this.nextAnswerSheetItemId !== null ? "Siguiente" : "Finalizar";
    },
    isPreviousButtonVisible() {
      return this.previousAnswerSheetItemId !== null;
    },
    localSelectedAnswerId: {
      get() {
        return this.selectedAnswerId;
      },
      set(localSelectedAnswerId) {
        this.emitAnswerSelectedEvent(localSelectedAnswerId);
      },
    },
  },
  methods: {
    async loadNextAnswerSheetItem() {
      this.$emit("load-next-answer-sheet-item");
    },
    async loadPreviousAnswerSheetItem() {
      this.$emit("load-previous-answer-sheet-item");
    },
    emitAnswerSelectedEvent(selectedAnswerId) {
      this.$emit("answer-selected", selectedAnswerId);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

/** @define answer-sheet-item; weak */
.answer-sheet-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @include laptop {
    justify-content: left;
  }
}

.answer-sheet-item__previous-btn {
  color: $primary-color;
  margin-left: -2.5em;
  margin-right: auto;
  @include laptop {
    margin-left: -5.5em;
    margin-right: auto;
  }
}

.answer-sheet-item__exercise {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.answer-sheet-item__evaluate-btn {
  width: 100%;
  margin: 0.5em 0;
  flex-grow: 1;

  @include laptop {
    width: 70%;
    padding-bottom: 5em;
  }
}

.answer-sheet-item__answers {
  margin: 3em 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  > .exercise-answer {
    flex-grow: 20;
  }
  @include laptop {
    margin-bottom: 1em;
    width: 100%;
    justify-content: left;
    text-align: left;
  }
}

/** @define previous-btn; weak */
.previous-btn__img {
  transform: rotate(90deg);
  width: 8%;
  @include laptop {
    width: 25%;
  }
}

.previous-btn__img > path {
  fill: $primary-color;
}

/** @define answers; */
.answers__item {
  @include laptop {
    padding-left: 2em;
  }
}
</style>

<style>
/** @define answer-sheet-item; weak */
.answer-sheet-item__previous-btn > button {
  text-align: left;
}
</style>
