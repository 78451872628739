<template>
  <div class="select-major">
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <div class="main-content__school-img">
          <SchoolImage />
        </div>

        <div class="main-content__input-drop-down">
          <InputDropdown
            v-model="inputModel"
            :items="items"
            :item-template="baseItem"
            :placeholder="placeholder"
            :loading="loadingItems"
            @changed="inputChange"
            @selected="selectItem"
          />
        </div>
        <div class="main-content__caption">
          <p class="caption__title">
            ¿Aún no te decides?
          </p>
          <span>
            Recuerda que puedes añadir otras opciones en cualquier momento.
          </span>
        </div>
        <div class="main-content__btn">
          <BaseButton
            style-modifier="secondary"
            :is-disabled="selectedItemId === null"
            @click="
              $router.push({
                name: 'RegisterAsAStudentPage',
                params: {
                  desiredMajorId: selectedItemId,
                  desiredMajor: selectedItem,
                },
              })
            "
          >
            Siguiente
          </BaseButton>
        </div>
      </div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
// eslint-disable-next-line import/extensions
import SchoolImage from "@/assets/school.svg?inline";
import InputDropdown from "@/components/input_dropdown/InputDropdown.vue";
import BaseItem from "@/components/input_dropdown/BaseItem.vue";
import SearchMajor from "@/use_cases/select_major/SearchMajor";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";

export default {
  name: "SelectMajorPage",
  components: {
    BaseButton,
    SchoolImage,
    InputDropdown,
    ContentSidebarLayout,
  },
  data() {
    return {
      baseItem: BaseItem,
      inputModel: "",
      placeholder: "Busca tu escuela/carrera deseada",
      searchMajorServiceRequest: "",
      searchMajorService: "",
      searchMajorResponse: "",
      minLength: 3,
      rootSearchTextChanged: true,
      items: [],
      loadingItems: false,
      selectedItem: null,
      selectedItemId: null,
    };
  },
  mounted() {
    this.$emit("load-finished");
  },
  methods: {
    async searchMajor() {
      this.items = await SearchMajor.getMajors(this.inputModel);
    },
    async inputChange(inputModel, validString) {
      this.selectedItem = null;
      this.selectedItemId = null;
      this.rootSearchTextChanged = inputModel.length > this.inputModel.length;
      this.inputModel = inputModel;
      if (inputModel.length === this.minLength && this.rootSearchTextChanged && validString) {
        this.loadingItems = true;
        await this.searchMajor();
        this.loadingItems = false;
      }
    },
    selectItem(item, id) {
      this.selectedItem = item;
      this.selectedItemId = id;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define main-content */
.main-content {
  @extend %font-form;

  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-left: 1.8em;
  margin-right: 1.8em;
}

.main-content__school-img {
  width: 80%;
  margin: 3em;
  @include laptop {
    width: 50%;
    margin: 1em;
    padding-top: 3em;
  }
}

.main-content__input-drop-down {
  width: 90%;

  @include laptop {
    width: 60%;
    padding-top: 1em;
  }
}

.main-content__btn {
  flex-grow: 1;
  width: 95%;
  padding-top: 3em;
  @include laptop {
    width: 70%;
  }
}

.main-content__caption {
  @extend %font-body-1;

  text-align: center;
  justify-content: center;
  width: 80%;
  padding-bottom: 2em;
  padding-top: 2em;

  @include laptop {
    width: 50%;
  }
}

/** @define caption */
.caption__title {
  @extend %font-body-1;

  color: $red;
  text-align: center;
  padding-bottom: 0.01em;
}
</style>
