<template>
  <div class="title-bar" @resize="screenWidth = window.innerWidth">
    <router-link :to="{ name: 'Home' }"><LogoText class="title-bar__title" /></router-link>
    <div v-if="isQuickMenuVisible" class="title-bar__quick_menu">
      <div data-test-id="show-courses-button" class="quick-menu__option" @click="onClickPractice">
        <GraduationCapImage class="option__icon" />
        <div class="option__caption">
          <span>PRACTICA</span>
        </div>
      </div>
      <div data-test-id="show-simulation-exams-button" class="quick-menu__option" @click="onClickSimulationExams">
        <img class="option__icon_img" src="../../assets/simulation-exams.png" alt="simulation exam" />
        <div class="option__caption">
          <span>EXAMENES</span>
        </div>
      </div>
      <div data-test-id="show-products-page-button" class="quick-menu__option" @click="onClickStore">
        <StoreImage class="option__icon_store" />
        <div class="option__caption">
          <span>TIENDA</span>
        </div>
      </div>
      <div data-test-id="show-my-courses-button" class="quick-menu__option" @click="onClickCourses">
        <CourseImage class="option__icon_course" />
        <div class="option__caption">
          <span>CURSOS</span>
        </div>
      </div>
    </div>
    <StudentCoinsView v-if="!isSimulationExamMenuIconVisible" class="title__coins" />
    <ExamImage v-if="isSimulationExamMenuIconVisible" class="title-bar__exam-image" @click="emitExamImageClick" />
    <div class="title-bar__log-out">
      <LogOutMenu class="title-bar__log-out-menu" data-test-id="menu-button" @click-log-out-menu="clickLogOutMenu" />
      <LogOutButton
        v-if="isLogOutMenuEnabled"
        class="title-bar__log-out-button"
        data-test-id="log-out-button"
        @log-out="logOut"
      />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import localForage from "localforage";
import ExamImage from "@/assets/exam.svg?inline";
import LogoText from "@/components/logo_text/LogoText.vue";
import StudentCoinsView from "@/use_cases/show_student_coins/StudentCoinsView.vue";
import LogOutMenu from "@/components/log_out/LogOutMenu.vue";
import LogOutButton from "@/components/log_out_button/LogOutButton.vue";
import StoreImage from "@/assets/store.svg?inline";
import GraduationCapImage from "@/assets/graduation-cap.svg?inline";
import CourseImage from "@/assets/course.svg?inline";

export default {
  name: "TitleBar",
  components: {
    LogOutButton,
    LogOutMenu,
    LogoText,
    StudentCoinsView,
    ExamImage,
    StoreImage,
    GraduationCapImage,
    CourseImage,
  },
  props: {
    isSimulationExamMenuIconVisible: {
      type: Boolean,
      required: true,
    },
    isQuickMenuVisible: {
      type: Boolean,
      required: false,
    },
    courseProgressId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isMobileResolution: false,
      isLogOutMenuEnabled: false,
    };
  },
  watch: {
    isSimulationExamMenuIconVisible() {
      return this.isSimulationExamMenuIconVisible;
    },
    isQuickMenuVisible() {
      return this.isSimulationExamMenuIconVisible;
    },
  },
  methods: {
    emitExamImageClick() {
      this.$emit("exam-image-click");
    },
    clickLogOutMenu() {
      this.isLogOutMenuEnabled = !this.isLogOutMenuEnabled;
    },
    async logOut() {
      try {
        await localForage.clear();
        await this.$router.push({ name: "Home" });
      } catch (e) {
        console.log(e);
      }
    },
    async onClickPractice() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "Home", params: { courseProgressId } });
    },
    async onClickSimulationExams() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "ShowSimulationExamsPage", params: { courseProgressId } });
    },
    async onClickStore() {
      await this.$router.push({ name: "ShowProductsPage" });
    },
    async onClickCourses() {
      await this.$router.push({ name: "ShowCoursesPage" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

a:link {
  color: white;
  text-decoration: none;
}
a:visited {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
  top: 1em;
  opacity: 0.5;
}
a:active {
  transform: scale(1.07);
}

/** @define title-bar */
.title-bar {
  display: flex;
  position: fixed;
  top: 0;
  background: $primary-color;
  width: 100%;
  height: 3em;
  color: $white;
  z-index: 3;
  justify-content: space-around;
  align-items: initial;
  @include laptop {
    height: 4.5em;
  }
}

.title-bar__hamburger-menu {
  cursor: pointer;
  @include laptop {
    margin-top: 0.5em;
  }
}

.title__coins {
  @include laptop {
    margin-top: 0.5em;
  }
}

.title-bar__hamburger-menu:hover {
  top: 1em;
  opacity: 0.5;
}

.title-bar__title {
  text-align: center;
  cursor: pointer;
  margin-top: 0.5em;

  @include laptop {
    margin-top: 1.3em;
  }
}

.title-bar__exam-image {
  margin-top: 0.3em;
  width: 20px;
  cursor: pointer;
}

.title-bar__exam-image:hover {
  top: 1em;
  opacity: 0.5;
}

.title-bar__log-out-menu {
  cursor: pointer;
  @include laptop {
    margin: 0.7em;
    padding-bottom: 1rem;
  }
}

.title-bar__log-out-menu:hover {
  opacity: 0.5;
}

.title-bar__log-out-button {
  cursor: pointer;
  position: absolute;
  bottom: -75%;
  right: 4%;
  @include laptop {
    bottom: -88%;
    right: 6%;
  }
}

.title-bar__log-out-button:hover {
  opacity: 0.8;
}

.title-bar__quick_menu {
  justify-content: space-around;
  width: 13%;
  flex-grow: 0.2;
  align-items: center;
  padding-left: 10em;
  visibility: hidden;
  display: none;
  @include laptop {
    visibility: visible;
    display: flex;
  }
}

/** @define quick-menu */
.quick-menu__option {
  visibility: hidden;
  @include laptop {
    visibility: visible;
    cursor: pointer;
    padding: 0 8.5%;

    &:hover {
      cursor: pointer;
      top: 1em;
      opacity: 0.5;
    }
    &:active {
      transform: scale(1.07);
    }
  }
}

/** @define option */
.option__icon {
  height: auto;
  margin: 0.45em 0.7em;
  flex-wrap: wrap;
  cursor: pointer;
  visibility: hidden;
  @include laptop {
    visibility: visible;
    display: initial;
  }
}

.option__icon_store {
  width: 2.1em;
  height: auto;
  margin: 0.25em 0.7em;
  flex-wrap: wrap;
  cursor: pointer;
  visibility: hidden;
  @include laptop {
    visibility: visible;
    display: initial;
  }
}

.option__icon_course {
  height: auto;
  margin: 0.25em 1em;
  flex-wrap: wrap;
  cursor: pointer;
  visibility: hidden;
  @include laptop {
    visibility: visible;
    display: initial;
  }
}

.option__icon_img {
  width: 2em;
  height: auto;
  margin: 0.25em 1.5em;
  cursor: pointer;
  visibility: hidden;
  display: none;
  @include laptop {
    visibility: visible;
    display: initial;
  }
}

.option__caption {
  @extend %font-body-3;

  visibility: hidden;
  display: none;
  width: 100%;
  @include laptop {
    visibility: visible;
    display: flex;
  }
}

/** @define hamburger-menu */
.hamburger-menu__img {
  margin-top: 1em;
  width: 20px;
}
</style>
