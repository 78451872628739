import Vue from "vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import VueAuthenticate from "vue-authenticate";
// eslint-disable-next-line import/no-extraneous-dependencies
import VueAxios from "vue-axios";
import axios from "axios";
import App from "./App.vue";
import router from "./router";

const baseURL = process.env.VUE_APP_API_URL;
const googleID = process.env.VUE_APP_GOOGLE_ID;
const facebookID = process.env.VUE_APP_FACEBOOK_ID;
const facebookAPIVersion = process.env.VUE_APP_FACEBOOK_API_VERSION;

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: baseURL,
  tokenPath: "jwt",
  oauthType: "2.0",
  providers: {
    google: {
      clientId: googleID,
      url: "/api/use-cases/google-auth/",
      requiredUrlParams: ["scope"],
      scope: ["profile", "email"],
      scopePrefix: "openid",
      redirectUri: `${window.location.origin}/`,
    },
    facebook: {
      clientId: facebookID,
      authorizationEndpoint: `https://www.facebook.com/v${facebookAPIVersion}/dialog/oauth?`,
      requiredUrlParams: ["display", "scope"],
      scope: ["email"],
      scopeDelimiter: ",",
      url: "/api/use-cases/facebook-auth/",
      redirectUri: `${window.location.origin}/`,
    },
  },
});
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
