<template>
  <div class="exercise-error-feedback">
    <p class="exercise-error-feedback__heading">
      Respuesta Incorrecta:
    </p>
    <p class="exercise-error-feedback__sub-heading">
      Intenta con una pista
    </p>

    <BaseButton style-modifier="text" class="exercise-error-feedback__get-hints-btn" @click="emitButtonClickEvent">
      + Obtener pista por 10 <CoinsImage class="get-hints-btn__img" />
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
// eslint-disable-next-line import/extensions
import CoinsImage from "@/assets/coins.svg?inline";

export default {
  name: "DemoExerciseErrorFeedbackComponent",
  components: {
    BaseButton,
    CoinsImage,
  },
  methods: {
    emitButtonClickEvent(event) {
      this.$emit("button-click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define exercise-error-feedback */
.exercise-error-feedback {
  color: $white;
  background: $error-color;
  padding: 1.5em 2.5em 2em;
}

.exercise-error-feedback__heading {
  @extend %font-title-2;

  text-align: left;
  margin-top: 0;
}

.exercise-error-feedback__sub-heading {
  @extend %font-subtitle-1;

  text-align: left;
  margin-top: 0;
}

.exercise-error-feedback__get-hints-btn {
  display: flex;
  justify-content: flex-end;
  @include laptop {
    display: none;
  }
}

/** @define get-hints-btn */
.get-hints-btn__img {
  width: 1.5em;
}
</style>
