<template>
  <div>
    <div class="bottom-navigation">
      <div class="bottom-navigation__option" data-test-id="show-courses" @click="onClickPractice">
        <GraduationCapImage class="option__img" />
        <div class="option__caption">
          <span>Práctica</span>
        </div>
      </div>
      <div class="bottom-navigation__option" @click="onClickSimulationExams">
        <img class="option__img" src="../../assets/simulation-exams.png" alt="simulation exam" />
        <div class="option__caption">
          <span data-test-id="show-simulation-exams">Examenes</span>
        </div>
      </div>
      <div class="bottom-navigation__option" @click="onClickStore">
        <StoreImage class="option__img" data-test-id="show-products-page" />
        <div class="option__caption">
          <span>Tienda</span>
        </div>
      </div>
      <div class="bottom-navigation__option" @click="onClickCourses">
        <CourseImage class="option__img" data-test-id="show-courses-page" />
        <div class="option__caption">
          <span>Cursos</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreImage from "@/assets/store.svg?inline";
import GraduationCapImage from "@/assets/graduation-cap.svg?inline";
import CourseImage from "@/assets/course.svg?inline";
import localForage from "localforage";

export default {
  name: "BottomNavigationMenu",
  components: {
    StoreImage,
    GraduationCapImage,
    CourseImage,
  },
  methods: {
    async onClickPractice() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "Home", params: { courseProgressId } });
    },
    async onClickSimulationExams() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "ShowSimulationExamsPage", params: { courseProgressId } });
    },
    async onClickStore() {
      await this.$router.push({ name: "ShowProductsPage" });
    },
    async onClickCourses() {
      await this.$router.push({ name: "ShowCoursesPage" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

a:link {
  color: $primary-color;
  text-decoration: none;
}
a:visited {
  color: $primary-color;
  text-decoration: none;
}
a:hover {
  color: $primary-color;
  text-decoration: none;
}

/** @define bottom-navigation */
.bottom-navigation {
  display: flex;
  width: 100%;
  height: 4em;
  position: fixed;
  flex-wrap: wrap;
  bottom: 0;
  z-index: 2;
  justify-content: center;
  background: $light-gray;
  box-shadow: 0 0 2em 0 rgba(0 0 0 / 30%), 0 6px 20px 0 rgba(0 0 0 / 19%);
}

.bottom-navigation__option {
  @extend %font-coins;

  display: flex;
  width: 5em;
  flex-wrap: wrap;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  text-decoration: none;
}

/** @define option */
.option__img {
  height: 2em;
}

.option__img:hover {
  cursor: pointer;
  top: 1em;
  opacity: 0.5;
}

.option__exam_img:hover {
  cursor: pointer;
  top: 1em;
  opacity: 0.5;
}

.option__caption:hover {
  cursor: pointer;
  top: 1em;
  opacity: 0.5;
}
</style>
