<template>
  <div>
    <BaseButton
      data-test-id="continue-with-social-account-btn"
      :style-modifier="authProvider"
      @click="continueWithSocialAccount()"
    >
      Continuar con {{ authProvider }}
    </BaseButton>
  </div>
</template>

<script>
import localForage from "localforage";
import BaseButton from "@/components/base_button/BaseButton.vue";

const authProvider = "facebook";
export default {
  name: "ContinueWithSocialAccountButton",
  components: { BaseButton },
  props: {
    authProvider: {
      type: String,
      required: true,
      validator: (prop) => ["facebook", "google"].includes(prop),
    },
  },
  methods: {
    async continueWithSocialAccount() {
      try {
        const response = await this.$auth.authenticate(authProvider);
        await this.redirectUserLoggedWithSocialAccount(response.data);
      } catch (err) {
        console.log(err);
      }
    },
    async redirectUserLoggedWithSocialAccount(response) {
      try {
        await localForage.setItem("JWT", response.jwt);

        if (response.is_student_registration_pending) {
          await localForage.setItem("isStudentRegistrationPending", true);
          await this.$router.push({ name: "DemoPage" });
        } else {
          await this.$router.push({ name: "Student" });
        }
      } catch (err) {
        console.log(err.message);
      }
    },
  },
};
</script>
