import {
  GetAnswerSheetItemService,
  GetAnswerSheetItemServiceRequest,
} from "@/use_cases/show_exam/GetAnswerSheetItemService";
import {
  SaveAnswerSheetItemService,
  SaveAnswerSheetItemServiceRequest,
} from "@/use_cases/show_exam/SaveAnswerSheetItemService";
import {
  EvaluateAnswerSheetService,
  EvaluateAnswerSheetServiceRequest,
} from "@/use_cases/show_exam/EvaluateAnswerSheetService";

const GetAnswerSheetItemResponse = function constructor({
  answerSheetId,
  nextAnswerSheetItemId,
  previousAnswerSheetItemId,
  answerSheetItemId,
  exercise,
  answers,
  selectedAnswerId,
  answerSheetItemNumber,
  hasHints = false,
} = {}) {
  this.answerSheetId = answerSheetId;
  this.nextAnswerSheetItemId = nextAnswerSheetItemId;
  this.previousAnswerSheetItemId = previousAnswerSheetItemId;
  this.answerSheetItemId = answerSheetItemId;
  this.exercise = exercise;
  this.answers = answers;
  this.selectedAnswerId = selectedAnswerId;
  this.answerSheetItemNumber = answerSheetItemNumber;
  this.hasHints = hasHints;
};

/** @returns {GetAnswerSheetItemResponse} * */
const getAnswerSheetItem = async ({ answerSheetId, answerSheetItemId }) => {
  const serviceRequest = new GetAnswerSheetItemServiceRequest({ answerSheetId, answerSheetItemId });
  const service = new GetAnswerSheetItemService(serviceRequest);
  return new GetAnswerSheetItemResponse(await service.do());
};

const SaveAnswerSheetItemResponse = function constructor() {};

const getSecondsSpentOnAnswerSheet = (initialTimestamp) => {
  const finalTimestamp = Date.now();
  return Math.round((finalTimestamp - initialTimestamp) / 1000);
};

/** @returns {SaveAnswerSheetItemResponse} * */
const saveAnswerSheetItem = async ({ answerSheetId, answerSheetItemId, selectedAnswerId, initialTimestamp }) => {
  const time = getSecondsSpentOnAnswerSheet(initialTimestamp);
  const serviceRequest = SaveAnswerSheetItemServiceRequest({
    answerSheetId,
    answerSheetItemId,
    selectedAnswerId,
    time,
  });
  const service = new SaveAnswerSheetItemService(serviceRequest);
  return new SaveAnswerSheetItemResponse(await service.do());
};

const EvaluateAnswerSheetResponse = function constructor() {};

/** @returns {EvaluateAnswerSheetResponse} * */
const evaluateAnswerSheet = async ({ answerSheetId }) => {
  const serviceRequest = EvaluateAnswerSheetServiceRequest({
    answerSheetId,
  });
  const service = new EvaluateAnswerSheetService(serviceRequest);
  return new EvaluateAnswerSheetResponse(await service.do());
};

const ShowExam = { getAnswerSheetItem, saveAnswerSheetItem, evaluateAnswerSheet };

export default ShowExam;
