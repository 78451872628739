<template>
  <BaseCard class="product">
    <div class="product__section product__section--image p-1" data-test-id="product-image">
      <img class="product__image" :src="product.image" :alt="product.name" />
    </div>
    <div class="product__section product__section--info p-2">
      <div class="product__name" data-test-id="product-name">
        {{ product.name }}
      </div>
      <span class="product__description" data-test-id="product-description">
        {{ product.description }}
      </span>
      <div class="product__pricing">
        <div class="product__price" data-test-id="product-price">${{ product.price }}</div>
        <div class="product__buy-btn">
          <BaseButton
            style-modifier="text"
            :data-test-id="`buy-btn-${product.productId}`"
            @click="$emit('open-payment-method-modal', product)"
          >
            Comprar >
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/base_card/BaseCard.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";

export default {
  name: "CoinsBundleCard",
  components: {
    BaseCard,
    BaseButton,
  },

  props: {
    /** @type {{ productId: int , name: string , description: string, price: int , image: string, type: string }} * */
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_spacing.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

/** @define product */
.product {
  background: white;
  color: black;
  display: flex;
  width: 18.5em;
  height: 17em;
  border-color: $secondary-color;
  border-width: 0.2em;

  @include laptop {
    width: 60em;
  }
}

.product__section {
  display: grid;
  text-align: center;
  align-items: center;
  height: 16em;

  @include laptop {
    width: 53em;
  }
}

.product__section--image {
  width: 6em;

  @include laptop {
    width: 12em;
  }
}

.product__section--info {
  width: 12em;

  @include laptop {
    width: 58em;
  }
}

.product__image {
  max-width: 100%;
}

.product__name {
  @extend %font-section-title-2;

  color: $ipn-color;
  text-align: justify;
}

.product__description {
  @extend %font-body-2;

  text-align: left;
}

.product__pricing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product__price {
  @extend %font-section-title-2;

  color: $ipn-color;
  align-items: center;
}

.product__buy-btn {
  @extend %font-section-title-2;

  color: $ipn-color;
  text-align: right;
}
</style>
