<template>
  <div class="discount__container">
    <BaseCard
      :class="isShortMessageVisible === 1 ? 'discount__container-main-small' : 'discount__container-main-normal'"
    >
      <div class="discount__container-title-base">
        <p class="discount__container-title"><strong>Descuento especial</strong></p>
      </div>
      <div>
        <p v-if="isShortMessageVisible === 1" class="discount__container-body">
          Aprovecha nuestros <strong>descuentos</strong> especiales por los próximos:
          <strong>{{ discountTime }}</strong>
        </p>
        <p v-else class="discount__container-body">
          Hola <strong>{{ userName }}</strong
          >, sabemos lo mucho que deseas estudiar <strong>{{ carrierName }}</strong
          >, y para ayudarte a conseguirlo, tenemos para ti un descuento especial en nuestra version premium por los
          proximos <strong>{{ discountTime }}</strong
          >, que estamos seguros te ayudara a conseguir tu meta.
        </p>
      </div>
      <BaseButton
        style-modifier="text"
        class="discount__container-view-discount"
        data-test-id="view-discount"
        @click="$emit('go-to-store')"
      >
        VER DESCUENTOS
      </BaseButton>
    </BaseCard>
    <BaseButton
      style-modifier="close-modal"
      class="close-discount-button"
      data-test-id="close-discount"
      @click="$emit('close-discount')"
    >
      <CloseModalButtonImage class="close-btn__img" />
    </BaseButton>
  </div>
</template>

<script>
import BaseCard from "@/components/base_card/BaseCard.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
// eslint-disable-next-line import/extensions
import CloseModalButtonImage from "@/assets/close-modal-button.svg?inline";

export default {
  name: "BaseDiscount",
  components: {
    BaseCard,
    BaseButton,
    CloseModalButtonImage,
  },
  props: {
    carrierName: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    isShortMessageVisible: {
      type: Number,
      required: true,
    },
    discountTime: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_spacing.scss";

/** @define discount */
.discount__container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-color;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.discount__container-main-normal {
  display: flex;
  flex-direction: column;
  width: 35em;
  height: 30em;
  background: white;
  margin-bottom: 4.5rem;

  @include laptop {
    width: 45em;
    height: 35em;
  }
}

.discount__container-main-small {
  display: flex;
  flex-direction: column;
  width: 35em;
  height: 20%;
  background: white;

  @include laptop {
    width: 45em;
  }
}

.discount__container-title-base {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  color: white;
}

.discount__container-title {
  @extend %font-body-1;

  color: $ipn-color;
}

.discount__container-body {
  @extend %font-body-1;

  width: 90%;
  margin: auto;
  margin-bottom: 1em;
  color: black;
}

.discount__container-view-discount {
  width: 90%;
  margin: auto;
  margin-bottom: 1em;
  color: $ipn-color;
}

/** @define close-btn */
.close-btn__img {
  width: auto;

  @include laptop {
    width: auto;
  }
}
</style>
