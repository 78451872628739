<template>
  <div class="base-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseContainer",
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_fonts.scss";

/** @define base-container */
.base-container {
  font-size: map-get($font-base, size);
  width: 82%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
