<template>
  <BaseModal
    :is-close-button-visible="true"
    :is-visible="isVisible"
    style-modifier="secondary"
    @hide-modal="resetDefaultFormStateAndClosePaymentModals"
  >
    <template #message>
      <div class="bank-card">
        <div class="bank-card__product-info">
          <div class="bank-card__product-section">
            <img :src="product.image" alt="product.name" />
          </div>
          <div class="bank-card__product-section">
            <div class="bank_card__product-price">$ {{ product.price }} MXN</div>
            <div class="bank_card__product_description">{{ product.description }}</div>
          </div>
        </div>
        <label class="bank-card__text-field">
          <span class="bank-card__label">Nombre del tarjetahabiente</span>
          <input
            v-model="userCardInfo.name"
            class="bank-card__input bank-card__input--centered"
            type="text"
            data-test-id="card-owner-input"
            size="20"
          />
          <span class="bank-card__warn" data-test-id="card-owner-warn">{{ ownerNameError ? ownerNameError : "" }}</span>
        </label>
        <div class="bank-card__info-group">
          <div class="bank-card__text-field bank-card__card_number">
            <span class="bank-card__label">No. Tarjeta</span>
            <input
              v-model="userCardInfo.number"
              class="bank-card__input bank-card__input--centered"
              type="text"
              data-test-id="card-number-input"
              size="20"
            />
          </div>
          <div class="bank-card__text-field bank-card__card_cvc">
            <span class="bank-card__label">CVV</span>
            <input
              v-model="userCardInfo.cvc"
              class="bank-card__input bank-card__input--centered"
              type="text"
              data-test-id="cvc-number-input"
              size="4"
            />
          </div>
        </div>
        <div data-test-id="card-number-warn" class="bank-card__text-field bank-card__warn">
          {{ cardNumberError ? cardNumberError : "" }}
        </div>
        <div data-test-id="cvc-number-warn" class="bank-card__text-field bank-card__warn">
          {{ cvcNumberError ? cvcNumberError : "" }}
        </div>
        <div>
          <label class="bank-card__text-field">
            <span class="bank-card__label">Fecha de expiración (MM/AAAA)</span>
            <div class="bank-card__info-group">
              <input
                v-model="userCardInfo.exp_month"
                class="bank-card__input bank-card__input--month bank-card__input--centered"
                type="text"
                data-test-id="expiration-month-input"
                size="2"
              />
              <input
                v-model="userCardInfo.exp_year"
                class="bank-card__input bank-card__input--year bank-card__input--centered"
                type="text"
                data-test-id="expiration-year-input"
                size="4"
              />
            </div>
          </label>
          <span class="bank-card__warn" data-test-id="expiration-date-warn">{{
            expirationDateError ? expirationDateError : ""
          }}</span>
        </div>
      </div>
      <BaseButton
        class="bank-card__payment-btn"
        style-modifier="text"
        :is-disabled="isWaitingForRequestResponse"
        data-test-id="pay-with-card-btn"
        @click="payWithCard"
      >
        Pagar
      </BaseButton>
      <span class="bank-card__warn" data-test-id="verify-info-warn">{{
        verifyCardInformation ? verifyCardInformation : ""
      }}</span>
      <SuccessfulPaymentModal
        :is-visible="isSuccessfulPaymentModalOpen"
        data-test-id="successful-payment-modal"
        @hide-modal="resetDefaultFormStateAndClosePaymentModals"
      />
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import BuyProduct from "@/use_cases/buy_product/BuyProduct";
import SuccessfulPaymentModal from "@/use_cases/buy_product/SuccessfulPaymentModal.vue";

const INVALID_OWNER_NAME = "Ingresa un nombre correcto";
const INVALID_CARD_NUMBER = "Ingresa un número de tarjeta válido";
const INVALID_CVC_NUMBER = "Ingresa un CVV válido";
const INVALID_EXPIRATION_DATE = "Ingresa una fecha válida ";
const VERIFY_CARD_INFO = "Revisa los datos de tu tarjeta";
const COULD_NOT_PROCEED_WITH_CONEKTA = "No se pudo generar el token de pago";
const DEFAULT_EMPTY_CARD_INFO = {
  name: "",
  number: "",
  cvc: "",
  exp_month: "",
  exp_year: "",
};

export default {
  name: "BankCardFormModal",
  components: { BaseModal, BaseButton, SuccessfulPaymentModal },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
    /** @type { productId: int , name: string , description: string, price: int , image: string, type: string } * */
    product: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      conekta: {},
      userCardInfo: JSON.parse(JSON.stringify(DEFAULT_EMPTY_CARD_INFO)),

      ownerNameError: null,
      cardNumberError: null,
      cvcNumberError: null,
      expirationDateError: null,
      verifyCardInformation: null,

      isWaitingForRequestResponse: false,
      isSuccessfulPaymentModalOpen: false,
    };
  },
  watch: {
    isVisible: {
      handler() {
        this.resetForm();
      },
      immediate: true,
    },
    userCardInfo: {
      handler() {
        if (this.verifyCardInformation) {
          this.verifyCardInformation = null;
        }
      },
      deep: true,
    },
    [`userCardInfo.name`]: {
      handler(name) {
        // eslint-disable-next-line no-restricted-globals
        this.ownerNameError = isNaN(name) && name.length > 0 ? null : INVALID_OWNER_NAME;
      },
    },
    [`userCardInfo.number`]: {
      handler(number) {
        this.cardNumberError = this.conekta.card.validateNumber(number) ? null : INVALID_CARD_NUMBER;
      },
    },
    [`userCardInfo.cvc`]: {
      handler(cvc) {
        // eslint-disable-next-line no-restricted-globals
        this.cvcNumberError = !isNaN(cvc) && cvc.length > 0 && cvc.length < 4 ? null : INVALID_CVC_NUMBER;
      },
    },
    [`userCardInfo.exp_month`]: {
      handler(expMonth) {
        this.expirationDateError = this.isValidExpirationDate(expMonth, this.userCardInfo.exp_year);
      },
    },
    [`userCardInfo.exp_year`]: {
      handler(expYear) {
        this.expirationDateError = this.isValidExpirationDate(this.userCardInfo.exp_month, expYear);
      },
    },
  },
  mounted() {
    const { NODE_ENV } = process.env;
    if (NODE_ENV === "production" || NODE_ENV === "development") {
      // eslint-disable-next-line no-undef
      this.conekta = Conekta;
      this.conekta.setPublicKey(process.env.VUE_APP_CONEKTA_PUBLIC_KEY);
    }
  },
  methods: {
    isValidExpirationDate(expMonth, expYear) {
      return this.conekta.card.validateExpirationDate(expMonth, expYear) ? null : INVALID_EXPIRATION_DATE;
    },
    isCardInfoRight() {
      const formContainsData = Object.keys(this.userCardInfo)
        .map((field) => this.userCardInfo[field])
        .filter((data) => `${data}`.length && `${data}`.length > 0);
      return (
        formContainsData.length > 0 &&
        this.ownerNameError == null &&
        this.cardNumberError == null &&
        this.cvcNumberError == null &&
        this.expirationDateError == null
      );
    },
    payWithCard() {
      this.isWaitingForRequestResponse = true;
      if (this.isCardInfoRight()) {
        const cardObject = { card: this.userCardInfo };
        this.conekta.Token.create(cardObject, this.onSuccessTokenResponse, this.onErrorTokenResponse);
      } else {
        this.verifyCardInformation = VERIFY_CARD_INFO;
        this.isWaitingForRequestResponse = false;
      }
    },
    async onSuccessTokenResponse(cardToken) {
      const dataWithToken = { productId: this.product.productId, cardToken: cardToken.id };
      await BuyProduct.BuyProductWithCard(dataWithToken);
      this.isSuccessfulPaymentModalOpen = true;
      this.isWaitingForRequestResponse = false;
    },
    onErrorTokenResponse() {
      this.verifyCardInformation = COULD_NOT_PROCEED_WITH_CONEKTA;
      this.isWaitingForRequestResponse = false;
    },
    resetDefaultFormStateAndClosePaymentModals() {
      this.resetForm();
      this.isSuccessfulPaymentModalOpen = false;
      this.$emit("hide-modal");
    },
    resetForm() {
      this.ownerNameError = null;
      this.cardNumberError = null;
      this.cvcNumberError = null;
      this.expirationDateError = null;
      this.verifyCardInformation = null;
      this.userCardInfo = JSON.parse(JSON.stringify(DEFAULT_EMPTY_CARD_INFO));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_spacing.scss";
@import "~@/scss/_colors.scss";

.bank-card {
  width: 100%;
}

.bank-card__product-info {
  display: flex;
}

.bank-card__product-section {
  padding: $space-unit;
  margin: $space-unit;
}

.bank_card__product_description {
  @extend %font-caption-2;
}

.bank-card--full-width-input {
  width: 100%;
}

.bank-card__info-group {
  display: flex;
}

.bank-card__text-field {
  display: grid;
  padding: $space-unit;
}

.bank-card__label {
  @extend %font-caption-2;
}

.bank-card__input {
  margin: $space-unit;
}

.bank-card__card_number {
  width: 70%;
}

.bank-card__card_cvc {
  width: 30%;
}

.bank-card__input--centered {
  text-align: center;
}

.bank-card__input--month {
  width: 50%;
}

.bank-card__input--year {
  width: 50%;
}

.bank-card__warn {
  color: $red;
  @extend %font-caption-2;
}

.bank-card__payment-btn {
  color: white;
  font-weight: bold;
  background: $orange;
  border-radius: $space-unit;
  margin-right: $space-unit * 4;
  padding-right: $space-unit * 4;
  margin-left: $space-unit * 4;
  padding-left: $space-unit * 4;
}
</style>
