<template>
  <div :class="'circle-icon circle-icon--' + styleModifier">
    <div class="circle-icon__icon" @click="emitClickEvent">
      <slot name="icon"></slot>
    </div>

    <div class="circle-icon__caption" @click="emitClickEvent">
      <span>{{ caption }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "CircleIcon",
  props: {
    styleModifier: {
      type: String,
      required: false,
      default: "",
      validator: (prop) => ["featured", ""].includes(prop),
    },
    caption: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    emitClickEvent(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";

/** @define circle-icon; */
.circle-icon {
  @extend %font-caption-2;

  display: flex;
  flex-direction: column;
  width: 5em;
  height: auto;
  align-items: center;
  flex-wrap: wrap;

  @include laptop {
    width: 6em;
  }

  /* stylelint-disable plugin/selector-bem-pattern */
  &.circle-icon--featured {
    @extend %font-caption-1;
    @include laptop {
      width: 6em;
    }
  }
  /* stylelint-enable */
}

.circle-icon__icon {
  width: 100%;
  height: 5em;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  @include laptop {
    height: 6em;
  }
}

.circle-icon__caption {
  text-align: center;
  width: 100%;
  cursor: pointer;
}
</style>
