<template>
  <BaseModal
    :is-close-button-visible="true"
    :is-visible="isVisible"
    style-modifier="secondary"
    @hide-modal="$emit('hide-modal')"
  >
    <template #message>
      <div class="payment-info">
        <div class="payment-info__details p-1 m-1">
          <div class="payment-info__oxxo-image"><OxxoImage /></div>
          <div class="payment-info__amount-details">
            <div class="payment-info__title">Monto a pagar</div>
            <div class="payment-info__amount">${{ oxxoPaymentInfo.amountToPay }}MXN</div>
            <div class="payment-info__expiration-date">Pagar antes de {{ oxxoPaymentInfo.expirationDate }}</div>
            <div class="payment-info__notice">OXXO cobrará una comisión adicional al momento de realizar el pago.</div>
          </div>
        </div>
        <div class="payment-info__payment-reference">
          <div class="payment-info__reference-label">Referencia</div>
          <div class="payment-info__reference-box">{{ oxxoPaymentInfo.referenceNumber }}</div>
        </div>
        <div class="payment-info__divider" />
      </div>
      <div class="payment-instructions">
        <div class="ma-2"><strong>Instrucciones:</strong></div>
        <br />
        1. Acude a tu tienda OXXO más cercana.
        <br />
        2. Indica en caja que quieres realizar un pago referenciado (<strong>OXXO Pay</strong>)
        <br />
        3. Dicta el número de referencia de esta ficha
        <br />
        4. Realiza el pago en efectivo (Más la comisión de OXXO)
        <br />
        5. Al confirmar tu pago, el cajero te entregará un comprobante impreso.
        <strong>Verifica que se haya realizado correctamente. </strong>Conserva tu comprobante de pago.
        <br />
        6. Recibirás un correo confirmando tu pago en un plazo de 24 horas.
      </div>
      <BaseButton
        style-modifier="text"
        data-test-id="payment-reference-download-btn"
        class="payment-instructions__download-button"
        @click="getPaymentReferenceData"
      >
        Descargar Ficha
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import OxxoImage from "@/assets/oxxo.svg?inline";
import DownloadOxxoPDF from "@/use_cases/buy_product/DownloadOxxoPDF";

export default {
  name: "OxxoInstructionsModal",
  components: { BaseModal, BaseButton, OxxoImage },

  props: {
    /** @type {{ referenceNumber: string, amountToPay: float, expirationDate: date, barcodeUrl: string }} * */
    oxxoPaymentInfo: {
      required: true,
      type: Object,
    },
    isVisible: {
      required: true,
      type: Boolean,
    },
  },

  methods: {
    async getPaymentReferenceData() {
      const { paymentReferenceData } = await DownloadOxxoPDF.DownloadOxxoPDFReference(this.oxxoPaymentInfo);
      const paymentReferenceFile = new File([paymentReferenceData], "ficha-de-pago-oxxo.pdf", {
        type: "application/pdf",
      });
      this.openFile(paymentReferenceFile);
    },
    openFile(file) {
      const fileAnchor = document.createElement("a");
      fileAnchor.href = URL.createObjectURL(file);
      fileAnchor.click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_spacing.scss";
@import "~@/scss/_colors.scss";

.payment-info {
  width: 100%;
  display: grid;
}

.payment-info__details {
  justify-content: space-between;
  width: calc(100% - (4em / 4));
  display: flex;
}

.payment-info__oxxo-image {
  width: calc(40% - (1em / 4));
}

.payment-info__amount-details {
  width: calc(60% - (1em / 4));
}

.payment-info__title {
  color: gray;
  @extend %font-caption-3;
}

.payment-info__amount {
  color: black;
}

.payment-info__expiration-date {
  color: gray;
  @extend %font-caption-2;
}

.payment-info__notice {
  color: gray;
  margin-top: $space-unit * 4;
  @extend %font-icon-pop-ups;
}

.payment-instructions {
  text-align: left;
  margin-left: $space-unit * 3;
  margin-right: $space-unit * 3;
  margin-bottom: $space-unit * 3;
  padding-left: $space-unit * 3;
  padding-right: $space-unit * 3;
  @extend %font-caption-2;
}

.payment-info__payment-reference {
  margin-left: $space-unit * 2;
  margin-right: $space-unit * 2;
}

.payment-info__reference-label {
  color: gray;
  text-align: left;
  @extend %font-caption-1;
}

.payment-info__reference-box {
  font-weight: bolder;
  border: 1px solid gray;
  border-radius: $space-unit;
  background: lightgray;
  @extend %font-body-1;
}

.payment-info__divider {
  margin: $space-unit * 3 $space-unit;
  border-top: 1px rgb(160 160 160) solid;
}

.payment-instructions__download-button {
  color: white;
  font-weight: bold;
  background: $orange;
  border-radius: $space-unit;
  margin-right: $space-unit * 4;
  padding-right: $space-unit * 4;
  margin-left: $space-unit * 4;
  padding-left: $space-unit * 4;
}
</style>
