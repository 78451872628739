/* eslint-disable key-spacing,object-shorthand,no-unused-vars */
import APIService from "@/services/API";

const ValidateIfEmailIsAlreadyRegisteredServiceRequest = (email) => ({ email });

// eslint-disable-next-line camelcase
const ValidateIfEmailIsAlreadyRegisteredServiceResponse = ({ is_registered, auth_provider }) => ({
  isRegistered: is_registered,
  authProvider: auth_provider,
});

class ValidateIfEmailIsAlreadyRegisteredService extends APIService {
  constructor(validateIfEmailIsAlreadyRegisteredServiceRequest) {
    super();
    this.serviceURL = "unregistered-user/validate-if-email-is-already-registered/";
    this.response = null;
    this.request = validateIfEmailIsAlreadyRegisteredServiceRequest;
  }

  async do() {
    this.response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
    return ValidateIfEmailIsAlreadyRegisteredServiceResponse(this.response.data);
  }
}
export { ValidateIfEmailIsAlreadyRegisteredService, ValidateIfEmailIsAlreadyRegisteredServiceRequest };
