<template>
  <div class="exam-page">
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <AnswerSheetItem
          v-model="selectedAnswerId"
          :answer-sheet-id="answerSheetId"
          :answer-sheet-item-id="answerSheetItemId"
          :answer-sheet-item-number="answerSheetItemNumber"
          :answers="answers"
          :exercise="exercise"
          :next-answer-sheet-item-id="nextAnswerSheetItemId"
          :previous-answer-sheet-item-id="previousAnswerSheetItemId"
          data-test-id="answer-sheet-item"
          @load-next-answer-sheet-item="loadNextAnswerSheetItem"
          @load-previous-answer-sheet-item="loadPreviousAnswerSheetItem"
        ></AnswerSheetItem>
        <div class="main-content__hints_bottom_sheet">
          <VerticalSlide>
            <ExerciseHintsBottomSheet
              v-show="isHintsBottomSheetVisible"
              :exercise-hints="hints"
              data-test-id="exercise-hints-bottom-sheet"
            />
          </VerticalSlide>
        </div>

        <HintsFloatingButton
          v-show="isHintsFloatingButtonVisible"
          class="main-content__floating-button"
          data-test-id="show-hints-bottom-sheet-floating-button"
          @click="showHintsBottomSheet()"
        />
      </div>

      <div slot="sidebar">
        <ExerciseHintsSidebarBox
          v-show="isHintsSidebarBoxVisible"
          :exercise-hints="hints"
          :last-hint-index-to-load="lastHintIndexToLoad"
          data-test-id="exercise-hints-sidebar-box"
        />
      </div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import AnswerSheetItem from "@/use_cases/show_exam/AnswerSheetItemComponent.vue";
import VerticalSlide from "@/transitions/VerticalSlide.vue";
import ExerciseHintsBottomSheet from "@/components/exercise_hints/ExerciseHintsBottomSheet.vue";
import HintsFloatingButton from "@/components/HintsFloatingButton.vue";
import ExerciseHintsSidebarBox from "@/components/exercise_hints/ExerciseHintsSidebarBox.vue";
import ShowExam from "@/use_cases/show_exam/ShowExam";

export default {
  name: "ExamPage",
  components: {
    AnswerSheetItem,
    ContentSidebarLayout,
    VerticalSlide,
    ExerciseHintsBottomSheet,
    HintsFloatingButton,
    ExerciseHintsSidebarBox,
  },
  props: {
    answerSheetId: {
      type: String,
      required: true,
    },
    answerSheetItemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isHintsBottomSheetVisible: false,
      hasHints: false,
      hints: [],
      lastHintIndexToLoad: 0,
      /** @type {{ text: string, imageUrl: string }} * */
      exercise: {},
      /** @type {{ id: number, text: string, imageUrl: string } []} * */
      answers: [],
      answerSheetItemNumber: 0,
      previousAnswerSheetItemId: null,
      nextAnswerSheetItemId: null,
      initialTimestamp: null,
      selectedAnswerId: null,
    };
  },
  computed: {
    isHintsFloatingButtonVisible() {
      return this.hasHints && !this.isHintsBottomSheetVisible;
    },
    isHintsSidebarBoxVisible() {
      return this.hasHints;
    },
  },
  watch: {
    $route() {
      this.initializeData();
    },
  },
  async created() {
    await this.initializeData();
  },
  async mounted() {
    this.emitLoadFinished();
  },
  methods: {
    async initializeData() {
      await this.getAnswerSheetItem();
      this.initialTimestamp = Date.now();
      this.refreshMathJax();
    },
    async getAnswerSheetItem() {
      ({
        exercise: this.exercise,
        answers: this.answers,
        nextAnswerSheetItemId: this.nextAnswerSheetItemId,
        previousAnswerSheetItemId: this.previousAnswerSheetItemId,
        selectedAnswerId: this.selectedAnswerId,
        answerSheetItemNumber: this.answerSheetItemNumber,
        hasHints: this.hasHints,
      } = await ShowExam.getAnswerSheetItem({
        answerSheetId: this.answerSheetId,
        answerSheetItemId: this.answerSheetItemId,
      }));
    },
    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          MathJax.Hub.queue.Push(["Typeset", MathJax.Hub]); // eslint-disable-line
        });
      }
    },
    emitLoadFinished() {
      this.$emit("load-finished");
    },
    async loadNextAnswerSheetItem() {
      await this.loadAnswerSheetItem(this.nextAnswerSheetItemId);
    },
    async loadPreviousAnswerSheetItem() {
      await this.loadAnswerSheetItem(this.previousAnswerSheetItemId);
    },
    async loadAnswerSheetItem(answerSheetItemId) {
      if (this.selectedAnswerId !== null) {
        await this.saveAnswerSheetItem();
      }
      if (answerSheetItemId === null) {
        await this.evaluateAnswerSheetItem();
        await this.$router.push({
          name: "AnswerSheetResultsSummaryPage",
          params: {
            answerSheetId: this.answerSheetId,
          },
        });
      } else {
        await this.$router.push({
          name: "ExamPage",
          params: {
            answerSheetId: this.answerSheetId,
            answerSheetItemId,
          },
        });
      }
    },
    async saveAnswerSheetItem() {
      await ShowExam.saveAnswerSheetItem({
        answerSheetId: this.answerSheetId,
        answerSheetItemId: this.answerSheetItemId,
        selectedAnswerId: this.selectedAnswerId,
        initialTimestamp: this.initialTimestamp,
      });
    },
    async evaluateAnswerSheetItem() {
      await ShowExam.evaluateAnswerSheet({
        answerSheetId: this.answerSheetId,
      });
    },
    showHintsBottomSheet() {
      this.isHintsBottomSheetVisible = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";

/** @define exam-page; */
.exam-page {
  display: flex;
  width: 100%;
}

/** @define main-content; weak */
.main-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @include laptop {
    justify-content: left;
  }
}

.main-content__exercise {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-content__evaluate-btn {
  width: 100%;
  margin: 0.5em 0;
  flex-grow: 1;

  @include laptop {
    width: 70%;
    padding-bottom: 5em;
  }
}

.main-content__feedback {
  width: 100%;
}

.main-content__hints {
  width: 100%;
  @include laptop {
    display: none;
  }
}

.main-content__answers {
  margin: 3em 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > .exercise-answer {
    flex-grow: 20;
  }

  @include laptop {
    margin-bottom: 1em;
    width: 100%;
    justify-content: left;
    text-align: left;
  }
}

.main-content__floating-button {
  @include laptop {
    display: none;
  }
}

.main-content__hints_bottom_sheet {
  width: 100%;
  @include laptop {
    display: none;
  }
}

/** @define answers; */
.answers__item {
  @include laptop {
    padding-left: 2em;
  }
}
</style>

<style>
/** @define main-content; weak */
.main-content__previous-btn > button {
  text-align: left;
}
</style>
