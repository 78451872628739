<template>
  <router-view @load-finished="emitLoadFinished" @update-simulation-exam-menu-icon="emitUpdateSimulationExamMenuIcon" />
</template>

<script>
import GetActiveCourseProgressId from "@/use_cases/get_active_course_progress_id/GetActiveCourseProgressId";

const STUDENT_ROUTE_NAME = "Student";

export default {
  name: "StudentPage",
  watch: {
    async $route() {
      await this.redirectToShowSubjectsPageWhenIsStudentPageRoot();
    },
  },
  async created() {
    await this.redirectToShowSubjectsPageWhenIsStudentPageRoot();
  },
  methods: {
    async redirectToShowSubjectsPageWhenIsStudentPageRoot() {
      if (this.$route.name === STUDENT_ROUTE_NAME) {
        const response = await GetActiveCourseProgressId.getActiveCourseProgressId();
        await this.$router.push({
          name: "ShowSubjectsPage",
          params: { courseProgressId: response.activeCourseProgressId },
        });
      }
    },
    emitLoadFinished() {
      this.$emit("load-finished");
    },
    emitUpdateSimulationExamMenuIcon(status) {
      this.$emit("update-simulation-exam-menu-icon", status);
    },
  },
};
</script>
