<template>
  <ContentSidebarLayout>
    <div slot="main-content" class="main-content">
      <BaseContainer>
        <ExamExercise
          v-model="selectedAnswerId"
          :exercise-number="1"
          :exercise="exercise"
          exercise-image-position="beforeText"
          :answers="exerciseAnswers"
          :is-selected-answer-correct="isSelectedAnswerCorrect"
          :are-answers-disabled="disableExerciseAnswer"
          class="main-content__exercise"
          data-test-id="exam-exercise"
          @answer-selected="resetAnswerStatus"
        >
          <div slot="exerciseImage" class="main-content__img" data-test-id="exam-exercise-image">
            <GraduationCapImage />
          </div>
        </ExamExercise>

        <div v-show="isBtnContainerVisible" class="main-content__evaluate-btn">
          <BaseButton
            class="evaluate-btn__btn"
            style-modifier="secondary"
            :is-disabled="selectedAnswerId === null"
            data-test-id="demo-evaluate-button"
            @click="evaluateSelectedAnswer"
          >
            Siguiente
          </BaseButton>
        </div>
      </BaseContainer>

      <div class="main-content__feedback">
        <VerticalSlide>
          <DemoExerciseSuccessFeedbackComponent
            v-if="isSuccessFeedbackComponentVisible"
            @button-click="$router.push({ name: 'DemoSuccessfullyCompletedPage' })"
          >
            Siguiente
          </DemoExerciseSuccessFeedbackComponent>
        </VerticalSlide>

        <VerticalSlide @after-leave="showHintsBottomSheet(false)">
          <DemoExerciseErrorFeedbackComponent
            v-show="isErrorFeedbackComponentVisible"
            @button-click="showHintsBottomSheet(false)"
          />
        </VerticalSlide>
      </div>
      <div class="main-content__hints">
        <VerticalSlide @after-leave="isLeaveHintsTransitionDone = true">
          <ExerciseHintsBottomSheet
            v-show="isHintsBottomSheetVisible"
            :exercise-hints="exerciseHints"
            :last-hint-index-to-load="lastHintIndexToLoad"
            data-test-id="exercise-hints-bottom-sheet"
            @button-click="updateHintCounter"
          />
        </VerticalSlide>
      </div>
      <HintsFloatingButton
        v-show="isHintsFloatingButtonVisible"
        class="main-content__floating-button"
        @click="showHintsBottomSheet(true)"
      />

      <DemoExerciseHintsModalComponent v-model="isHintsModalComponentVisible" />
    </div>

    <div slot="sidebar">
      <ExerciseHintsSidebarBox
        :exercise-hints="exerciseHints"
        :last-hint-index-to-load="lastHintIndexToLoad"
        data-test-id="exercise-hints-sidebar-box"
        @button-click="updateHintCounter"
      />
    </div>
  </ContentSidebarLayout>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
// eslint-disable-next-line import/extensions
import GraduationCapImage from "@/assets/graduation-cap.svg?inline";
import Demo from "@/use_cases/demo/Demo";
import DemoExerciseSuccessFeedbackComponent from "@/use_cases/demo/DemoExerciseSuccessFeedbackComponent.vue";
import DemoExerciseErrorFeedbackComponent from "@/use_cases/demo/DemoExerciseErrorFeedbackComponent.vue";
import VerticalSlide from "@/transitions/VerticalSlide.vue";
import ExerciseHintsBottomSheet from "@/components/exercise_hints/ExerciseHintsBottomSheet.vue";
import HintsFloatingButton from "@/components/HintsFloatingButton.vue";
import DemoExerciseHintsModalComponent from "@/use_cases/demo/DemoExerciseHintsModalComponent.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import ExerciseHintsSidebarBox from "@/components/exercise_hints/ExerciseHintsSidebarBox.vue";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import ExamExercise from "@/components/exam_exercise/ExamExercise.vue";

export default {
  name: "DemoPage",
  components: {
    ExamExercise,
    BaseContainer,
    BaseButton,
    GraduationCapImage,
    DemoExerciseSuccessFeedbackComponent,
    DemoExerciseErrorFeedbackComponent,
    ExerciseHintsBottomSheet,
    VerticalSlide,
    HintsFloatingButton,
    DemoExerciseHintsModalComponent,
    ExerciseHintsSidebarBox,
    ContentSidebarLayout,
  },
  data() {
    return {
      exerciseText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " +
        "Nullam sed orci nec nunc finibus faucibus. Vestibulum tincidunt quam justo, in pretium magna egestas nec. " +
        "In feugiat eu lectus nec hendrerit. In sagittis euismod nisl, eget ornare velit varius posuere. " +
        "Sed et sodales dolor. Mauris varius tempor imperdiet. Vivamus id quam. ",
      exercise: {
        text:
          "La expresión algebraica  ________ describe la siguiente frase “cuatro veces el cubo de la suma de un " +
          "número y el dos”.",
      },
      /** @type {{ id: number, text: string }[]} * */
      exerciseAnswers: [
        {
          id: 1,
          text: "\\(4x^3+2\\)",
        },
        {
          id: 2,
          text: "\\(4\\left(x+2\\right)^3\\)",
        },
        {
          id: 3,
          text: "\\(4\\left(x^3+2\\right)\\)",
        },
        {
          id: 4,
          text: "\\(4\\left(x+2^3\\right)\\)",
        },
      ],
      exerciseHints: [
        {
          text:
            "Podemos dividir esta descripción en partes más simples y traducirlas a lenguaje algebraico. Un " +
            "número: Representemos el número con una variable, por ejemplo: X.",
        },
        {
          text:
            "La suma de un número y el dos: Sumamos el número x y 2 para obtener x + 2, El cubo de la suma de" +
            "un número y el dos: Elevamos al cubo el resultado anterior, es decir, (x + 2)³.",
        },
        {
          text:
            "Cuatro veces el cubo de la suma de un número y el dos: Multiplicamos por cuatro el resultado " +
            "anterior, es decir, 4(x + 2)³.",
        },
      ],
      /** @typo number * */
      selectedAnswerId: null,
      isSelectedAnswerCorrect: null,
      isHintsBottomSheetVisible: false,
      isLeaveHintsTransitionDone: true,
      hasHintsFloatingButtonBeenHidden: false,
      isHintsModalComponentVisible: false,
      isHintsSectionDiscovered: false,
      lastHintIndexToLoad: 0,
    };
  },

  computed: {
    isBtnContainerVisible() {
      return !(this.isSuccessFeedbackComponentVisible || this.isErrorFeedbackComponentVisible);
    },
    disableExerciseAnswer() {
      return (
        this.isSuccessFeedbackComponentVisible ||
        (this.isErrorFeedbackComponentVisible && !this.isHintsSectionDiscovered)
      );
    },
    isSuccessFeedbackComponentVisible() {
      return this.isSelectedAnswerCorrect === true && this.isLeaveHintsTransitionDone === true;
    },
    isErrorFeedbackComponentVisible() {
      return this.isSelectedAnswerCorrect === null || this.isLeaveHintsTransitionDone !== true
        ? false
        : !this.isSelectedAnswerCorrect;
    },
    feedbackStyleModifier() {
      if (this.isSelectedAnswerCorrect === null || this.isLeaveHintsTransitionDone !== true) {
        return "";
      }

      return this.isSelectedAnswerCorrect ? "success" : "error";
    },
    isHintsFloatingButtonVisible: {
      get() {
        return !(
          this.isSuccessFeedbackComponentVisible ||
          this.isErrorFeedbackComponentVisible ||
          this.isHintsBottomSheetVisible ||
          this.hasHintsFloatingButtonBeenHidden
        );
      },

      set(isHintsFloatingButtonVisible) {
        if (isHintsFloatingButtonVisible === false) {
          this.hasHintsFloatingButtonBeenHidden = true;
        }
      },
    },
  },

  mounted() {
    this.refreshMathJax();
    this.$emit("load-finished");
  },

  methods: {
    evaluateSelectedAnswer() {
      this.isSelectedAnswerCorrect = Demo.evaluateAnswer(this.selectedAnswerId);
      this.isHintsBottomSheetVisible = false;
      this.isHintsFloatingButtonVisible = false;
    },
    showHintsBottomSheet(showHintsModalComponent = false) {
      this.isHintsBottomSheetVisible = true;
      this.isLeaveHintsTransitionDone = false;
      this.isHintsFloatingButtonVisible = false;
      this.isHintsModalComponentVisible = showHintsModalComponent;
    },
    resetAnswerStatus() {
      this.isSelectedAnswerCorrect = null;
    },
    resetSelectedAnswer() {
      this.isHintsSectionDiscovered = true;
      this.isSelectedAnswerCorrect = null;
    },
    updateHintCounter(hintIndex) {
      this.lastHintIndexToLoad = hintIndex;
      this.resetSelectedAnswer();
    },
    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          MathJax.Hub.queue.Push(["Typeset", MathJax.Hub]); // eslint-disable-line
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";

/** @define main-content; weak */
.main-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @include laptop {
    justify-content: left;
  }
}

.main-content__exercise {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-content__img {
  width: 40%;

  @include laptop {
    width: 15%;
  }
}

.main-content__evaluate-btn {
  width: 100%;
  margin: 0.5em 0;
  flex-grow: 1;

  @include laptop {
    width: 70%;
    padding-bottom: 5em;
  }
}

.main-content__feedback {
  width: 100%;
  bottom: 0;
}

.main-content__hints {
  width: 100%;
  @include laptop {
    display: none;
  }
}

.main-content__answers {
  margin: 3em 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > .exercise-answer {
    flex-grow: 20;
  }

  @include laptop {
    margin-bottom: 1em;
    width: 100%;
    justify-content: left;
    text-align: left;
  }
}

.main-content__floating-button {
  @include laptop {
    display: none;
  }
}

/** @define answers; */
.answers__item {
  @include laptop {
    padding-left: 2em;
  }
}
</style>
