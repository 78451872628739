<template>
  <BaseCard class="product">
    <div class="product__section product__section--image p-1" data-test-id="product-image">
      <img class="product__image" :src="product.image" :alt="product.name" />
    </div>
    <div class="product__section product__section--info p-2">
      <div>
        <span :class="'product__name product__name--' + styleModifier" data-test-id="product-name">
          {{ product.name }}
        </span>
        <span class="product__discount" data-test-id="product__discount"
          >- 20% de descuento por tiempo limitado:
          <div class="product__countdown">
            <CountdownTimer class="product__count-left" :time-in-minutes="minutesLeft" @time-out="$emit('time-out')" />
          </div>
        </span>
        <span class="product__description" data-test-id="product-description"> de {{ product.description }} </span>
      </div>
      <div class="product__price--before" data-test-id="product-price"><del>$199.00</del></div>
      <div class="product__price" data-test-id="product-price">${{ product.price }}</div>
      <div class="product__buy-btn">
        <BaseButton
          style-modifier="text"
          :data-test-id="`buy-btn-${product.productId}`"
          @click="$emit('open-payment-method-modal', product)"
        >
          Comprar >
        </BaseButton>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/base_card/BaseCard.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import CountdownTimer from "../../components/countdown_timer/CountdownTimer.vue";

export default {
  name: "SubscriptionCardDiscount",
  components: {
    BaseCard,
    BaseButton,
    CountdownTimer,
  },
  /** @type {{ productId: int , name: string , description: string, price: int , image: string, type: string }} * */
  props: {
    product: {
      type: Object,
      required: true,
    },
    styleModifier: {
      type: String,
      required: true,
      validator: (prop) => ["primary", "secondary"].includes(prop),
    },
    minutesLeft: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_spacing.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

/** @define product */
.product {
  background: $primary-color;
  color: black;
  display: flex;
  width: 19em;
  height: 18em;
  align-content: center;

  @include laptop {
    width: 60em;
    height: 17em;
  }
}

.product__section {
  display: grid;
  height: 16em;
  width: 8em;
  color: white;
  text-align: center;
  align-items: center;

  @include laptop {
    width: 53em;
  }
}

.product__section--image {
  width: 6em;

  @include laptop {
    width: 12em;
  }
}

.product__section--info {
  width: 12em;

  @include laptop {
    width: 55em;
  }
}

.product__image {
  max-width: 100%;
}

.product__name {
  @extend %font-section-title-2;

  text-align: justify;
  &--primary {
    color: $secondary-color;
  }

  &--secondary {
    color: $success-color;
  }
}

.product__description {
  @extend %font-body-3;

  color: $white;
}

.product__discount {
  @extend %font-body-3;

  color: $secondary-color;
}

.product__countdown {
  display: flex;
  justify-content: space-around;
}

.product__count-left {
  width: 20%;
}

.product__price {
  @extend %font-section-title-2;

  color: $white;
  text-align: center;
}

.product__price--before {
  @extend %font-body-3;

  color: $secondary-color;
}

.product__buy-btn {
  @extend %font-section-title-1;

  color: $secondary-color;
  text-align: right;
}
</style>
