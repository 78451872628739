<template>
  <div class="layout">
    <div class="layout__main-content">
      <slot name="main-content"></slot>
    </div>
    <div class="layout__sidebar">
      <slot name="sidebar"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentSidebarLayout",
};
</script>

<style scoped lang="scss">
@import "~@/scss/_resolutions.scss";

/** @define layout; */
.layout {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
}

.layout__main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @include laptop {
    width: 65%;
    height: 100%;
  }
}

.layout__sidebar {
  display: none;

  @include laptop {
    width: 35%;
    display: inline;
  }
}
</style>
