import { render, staticRenderFns } from "./InsufficientCoinsModalComponent.vue?vue&type=template&id=285110dc&scoped=true&"
import script from "./InsufficientCoinsModalComponent.vue?vue&type=script&lang=js&"
export * from "./InsufficientCoinsModalComponent.vue?vue&type=script&lang=js&"
import style0 from "./InsufficientCoinsModalComponent.vue?vue&type=style&index=0&id=285110dc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "285110dc",
  null
  
)

export default component.exports