<template>
  <OneButtonModal
    :is-visible="isVisible"
    :is-close-button-visible="true"
    style-modifier="secondary"
    primary-button-style-modifier="primary"
    @hide-modal="emitHideModalEvent"
    @button-click="redirectToStorePage"
  >
    <div slot="image" class="insufficient-coins-modal__piggy-bank-img">
      <InsufficientCoinsImage class="piggy-bank-img__img" />
    </div>

    <div slot="message" class="insufficient-coins-modal__message">
      <p>¡Ups!</p>
      <p>No tienes suficentes monedas</p>
    </div>

    <span slot="buttonText" class="insufficient-coins-modal__buy-coins-btn-text">Comprar monedas </span>
  </OneButtonModal>
</template>

<script>
// eslint-disable-next-line import/extensions
import InsufficientCoinsImage from "@/assets/insufficient-coins.svg?inline";
import OneButtonModal from "@/components/one_button_modal/OneButtonModal.vue";

export default {
  name: "InsufficientCoinsModalComponent",
  components: {
    OneButtonModal,
    InsufficientCoinsImage,
  },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isCloseButtonVisible: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
    emitNumberOfExercisesChosen(numberOfExercisesChosen) {
      this.$emit("number-of-exercises-chosen", numberOfExercisesChosen);
    },
    redirectToStorePage() {
      this.$router.push({ name: "ShowProductsPage" });
    },
  },
};
</script>

<style scoped lang="scss">
/** @define insufficient-coins-modal */
.insufficient-coins-modal__piggy-bank-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.insufficient-coins-modal__message {
  margin: 2em 0;
}

/** @define piggy-bank-img */
.piggy-bank-img__img {
  width: 40%;
}
</style>
