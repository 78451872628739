<template>
  <div class="exam-exercise">
    <div :class="`exam-exercise__exercise ${examExerciseStyleModifier}`" data-test-id="exercise">
      <ExerciseText data-test-id="exercise-text">
        <span>{{ exerciseNumber }}. </span>
        <span v-html="exercise.text"></span>
      </ExerciseText>
      <div v-show="this.$slots.exerciseImage" class="exercise__img" data-test-id="exercise-img">
        <slot name="exerciseImage"></slot>
      </div>
    </div>

    <div class="exam-exercise__answers">
      <ExerciseAnswer
        v-for="answer in answers"
        :key="answer.id"
        :ref="`exercise-answer-${answer.id}`"
        :checked="selectedAnswerId"
        name="exercise-answer"
        class="answers__item"
        :style-modifier="selectedAnswerId === answer.id ? selectedAnswerStyleModifier : ''"
        :answer="answer"
        :disabled="areAnswersDisabled"
        :data-test-id="`exercise-answer-${answer.id}`"
        @change="emitAnswerSelectedEvent"
      >
      </ExerciseAnswer>
    </div>
  </div>
</template>

<script>
import ExerciseText from "@/components/exam_exercise/ExerciseText.vue";
import ExerciseAnswer from "@/components/exam_exercise/ExerciseAnswer.vue";

if (process.env.NODE_ENV !== "testing") {
  MathJax.Hub.Config({ //eslint-disable-line
    "HTML-CSS": { linebreaks: { automatic: true } }, //eslint-disable-line
    SVG: { linebreaks: { automatic: true } }//eslint-disable-line
  });//eslint-disable-line
}

export default {
  name: "ExamExercise",
  components: { ExerciseAnswer, ExerciseText },
  model: {
    prop: "selectedAnswerId",
    event: "answer-selected",
  },
  props: {
    /** @type {{ text: string }} * */
    exercise: {
      type: Object,
      required: true,
    },
    exerciseImagePosition: {
      type: String,
      default: "afterText",
      validator: (prop) => ["afterText", "beforeText"].includes(prop),
    },
    exerciseNumber: {
      type: Number,
      default: null,
    },
    /** @type {{ id: number, text: string, imageUrl: string } []} * */
    answers: {
      type: Array,
      required: true,
    },
    isSelectedAnswerCorrect: {
      type: Boolean,
      default: null,
    },
    areAnswersDisabled: {
      type: Boolean,
      default: false,
    },
    selectedAnswerId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    examExerciseStyleModifier() {
      return this.exerciseImagePosition === "beforeText" ? "exam-exercise__exercise--image-before-text" : "";
    },
    selectedAnswerStyleModifier() {
      if (this.isSelectedAnswerCorrect === null) {
        return "primary";
      }

      return this.isSelectedAnswerCorrect ? "success" : "error";
    },
  },
  methods: {
    emitAnswerSelectedEvent(selectedAnswerId) {
      this.$emit("answer-selected", selectedAnswerId);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_resolutions.scss";

img {
  width: 100%;
}

/** @define exam-exercise; weak */
.exam-exercise {
  width: 100%;
}

.exam-exercise__exercise {
  display: flex;
  flex-direction: column;

  &--image-before-text {
    flex-direction: column-reverse;
  }
}

.exam-exercise__answers {
  margin: 3em 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @include laptop {
    margin-bottom: 1em;
    width: 100%;
    justify-content: left;
    text-align: left;
  }
}

/** @define exercise; */
.exercise__img {
  display: flex;
  justify-content: center;
  padding: 2.25em 0;
}
</style>

<style>
/** @define MathJax; */
.MathJax {
  font-size: 1.15em !important;
  margin-right: -0.5em !important;
}
</style>
