import {
  ShowExplanationService,
  ShowExplanationServiceRequest,
} from "@/use_cases/show_explanation/ShowExplanationService";

const ShowExplanationResponse = function constructor({
  exerciseText,
  exerciseImage,
  exerciseNumber,
  selectedAnswerText,
  selectedAnswerImage,
  correctAnswerImage,
  correctAnswerText,
  isCorrect,
  explanationText,
  explanationImage,
} = {}) {
  this.exerciseText = exerciseText;
  this.exerciseImage = exerciseImage;
  this.exerciseNumber = exerciseNumber;
  this.selectedAnswerText = selectedAnswerText;
  this.selectedAnswerImage = selectedAnswerImage;
  this.correctAnswerImage = correctAnswerImage;
  this.correctAnswerText = correctAnswerText;
  this.isCorrect = isCorrect;
  this.explanationText = explanationText;
  this.explanationImage = explanationImage;
};

/** @returns {ShowExplanationResponse} * */
const getExplanation = async ({ answerSheetItemId }) => {
  const serviceRequest = new ShowExplanationServiceRequest({ answerSheetItemId });
  const service = new ShowExplanationService(serviceRequest);
  return new ShowExplanationResponse(await service.do());
};

const ShowExplanation = { getExplanation };

export default ShowExplanation;
