<template>
  <div class="exercise-success-feedback">
    <p class="exercise-success-feedback__heading">
      Respuesta Correcta
    </p>

    <BaseButton
      class="exercise-success-feedback__btn"
      style="text-align: justify !important;"
      style-modifier="secondary"
      @click="emitButtonClickEvent"
    >
      <slot />
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";

export default {
  name: "DemoExerciseSuccessFeedbackComponent",
  components: {
    BaseButton,
  },
  methods: {
    emitButtonClickEvent(event) {
      this.$emit("button-click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define exercise-success-feedback */
.exercise-success-feedback {
  color: $white;
  background: $success-color;
  padding: 1.5em 2.5em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exercise-success-feedback__heading {
  @extend %font-title-2;

  text-align: center;
  margin-top: 0;
}

.exercise-success-feedback__btn {
  display: flex;
  width: 100%;

  @include laptop {
    width: 50%;
  }
}
</style>
