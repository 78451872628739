var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-major"},[_c('ContentSidebarLayout',[_c('div',{staticClass:"main-content",attrs:{"slot":"main-content"},slot:"main-content"},[_c('div',{staticClass:"main-content__school-img"},[_c('SchoolImage')],1),_c('div',{staticClass:"main-content__input-drop-down"},[_c('InputDropdown',{attrs:{"items":_vm.items,"item-template":_vm.baseItem,"placeholder":_vm.placeholder,"loading":_vm.loadingItems},on:{"changed":_vm.inputChange,"selected":_vm.selectItem},model:{value:(_vm.inputModel),callback:function ($$v) {_vm.inputModel=$$v},expression:"inputModel"}})],1),_c('div',{staticClass:"main-content__caption"},[_c('p',{staticClass:"caption__title"},[_vm._v(" ¿Aún no te decides? ")]),_c('span',[_vm._v(" Recuerda que puedes añadir otras opciones en cualquier momento. ")])]),_c('div',{staticClass:"main-content__btn"},[_c('BaseButton',{attrs:{"style-modifier":"secondary","is-disabled":_vm.selectedItemId === null},on:{"click":function($event){return _vm.$router.push({
              name: 'RegisterAsAStudentPage',
              params: {
                desiredMajorId: _vm.selectedItemId,
                desiredMajor: _vm.selectedItem,
              },
            })}}},[_vm._v(" Siguiente ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }