<template>
  <div class="answer-sheet-results-by-subject">
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <BaseContainer>
          <div class="answer-sheet-results-by-subject__heading">
            <h1 class="heading__title">Resultados</h1>
            <CircleIcon
              class="heading__subject-circle-icon"
              style-modifier="featured"
              :caption="subjectName"
              @click="$router.push({ name: 'ShowSimulationExamsPage' })"
            >
              <img slot="icon" :src="subjectImage" alt="subject circle icon" class="subject-circle-icon__icon" />
            </CircleIcon>
          </div>
          <div class="answer-sheet-results-by-subject__subjects-results">
            <CollapsibleItem
              v-for="(exercise, index) in exercisesResults"
              :key="index"
              :is-open="collapsibleItems[index]"
              :is-mobile-resolution="isMobileResolution"
              collapsible-style="white"
              class="subjects-results__item"
              :data-test-id="'collapsible-item-component-' + index"
              @collapsible-item-clicked="updateItems(index)"
            >
              <div v-if="!collapsibleItems[index]" slot="title" :data-test-id="'collapsible-item-header-' + index">
                <CorrectImage v-if="exercise.isCorrect" class="exercise__icon" data-test-id="correct-image" />
                <IncorrectImage v-else class="exercise__icon" data-test-id="incorrect-image" />
                <span>{{ exercise.number }}. </span>
                <span v-html="formatTitleItem(exercise.text.toString(), index)" />
              </div>
              <div v-if="collapsibleContents[index] !== undefined" slot="content" class="item__content">
                <div v-if="collapsibleContents[index] !== null" :data-test-id="'collapsible-item-content-' + index">
                  <CorrectImage v-if="exercise.isCorrect" class="exercise__icon" />
                  <IncorrectImage v-else class="exercise__icon" />
                  <span>{{ collapsibleContents[index].exerciseNumber }}. </span>
                  <span v-html="collapsibleContents[index].exerciseText" />
                  <div v-if="collapsibleItems[index]" class="exercise__img">
                    <img :src="collapsibleContents[index].exerciseImage" />
                  </div>
                  <div v-for="(answer, answerIndex) in collapsibleContents[index].answers" :key="answerIndex">
                    <ExerciseAnswer
                      v-if="collapsibleContents[index] !== null"
                      :key="answer.id"
                      :ref="`exercise-answer-${answer.id}`"
                      :checked="parseInt(collapsibleContents[index].selectedAnswerId)"
                      name="exercise-answer"
                      :style-modifier="
                        selectedAnswerStyleModifier(
                          collapsibleContents[index].selectedAnswerId,
                          collapsibleContents[index].correctAnswerId,
                          answer.id
                        )
                      "
                      :answer="answer"
                      :disabled="true"
                      :data-test-id="`exercise-answer-${answerIndex}`"
                    />
                  </div>
                  <div v-if="collapsibleContents[index].hasExplanation" class="explanation__button">
                    <router-link
                      :to="{
                        name: 'ExplanationPage',
                        params: { answerSheetId, answerSheetItemId: collapsibleContents[index].answerSheetItemId },
                      }"
                    >
                      <BaseButton
                        class="content__button"
                        style-modifier="secondary"
                        data-test-id="view-explanation-button"
                      >
                        VER EXPLICACIÓN 10
                        <CoinsImage class="button__image" />
                      </BaseButton>
                    </router-link>
                  </div>
                </div>
              </div>
            </CollapsibleItem>
          </div>
          <div class="answer-sheet-results-by-subject__accept-btn">
            <router-link :to="{ name: 'AnswerSheetResultsSummaryPage', params: { answerSheetId } }">
              <BaseButton style-modifier="secondary" data-test-id="accept-button">
                Aceptar
              </BaseButton>
            </router-link>
          </div>
        </BaseContainer>
      </div>

      <div slot="sidebar"></div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import localForage from "localforage";
import CorrectImage from "@/assets/correct.svg?inline";
import IncorrectImage from "@/assets/incorrect.svg?inline";
import CoinsImage from "@/assets/coins.svg?inline";
// eslint-disable-next-line max-len
import ShowAnswerSheetResultsBySubject from "@/use_cases/show_answer_sheet_results_by_subject/ShowAnswerSheetResultsBySubject";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import CircleIcon from "@/components/circle_icon/CircleIcon.vue";
import ExerciseAnswer from "@/components/exam_exercise/ExerciseAnswer.vue";
import CollapsibleItem from "../../components/collapsible_item/CollapsibleItem.vue";

const LAPTOP_WIDTH = 1200;
const MAX_TITLE_LENGTH = 90;
const MAX_TITLE_LENGTH_MOBILE = 60;

export default {
  name: "AnswerSheetResultsBySubjectPage",
  components: {
    BaseContainer,
    ContentSidebarLayout,
    BaseButton,
    CircleIcon,
    CollapsibleItem,
    CorrectImage,
    IncorrectImage,
    CoinsImage,
    ExerciseAnswer,
  },
  props: {
    answerSheetId: {
      type: String,
      required: true,
    },
    subjectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      /** @type {{
       * id: string, number: number, text: string, is_correct: boolean
       * }[]} * */
      exercisesResults: [],
      collapsibleItems: [],
      collapsibleContents: [],
      isMobileResolution: false,
      subjectName: "",
      subjectImage: "",
    };
  },
  created() {
    this.initializeCollapsibleItems();
  },
  async mounted() {
    const response = await ShowAnswerSheetResultsBySubject.getAnswerSheetResultsBySubject({
      answerSheetId: this.answerSheetId,
      subjectId: this.subjectId,
    });
    this.exercisesResults = response.exercisesResults;
    await this.getSubjectNameAndImage();
    this.initializeCollapsibleItems();
    this.isMobileResolution = window.innerWidth < LAPTOP_WIDTH;
    await this.refreshMathJax();
    await localForage.setItem("subjectId", this.subjectId);

    this.$emit("load-finished");
  },
  methods: {
    selectedAnswerStyleModifier(selectedAnswerId, correctAnswerId, answerId) {
      if (answerId === correctAnswerId) {
        return "success";
      }
      if (answerId === selectedAnswerId && selectedAnswerId !== correctAnswerId) {
        return "error";
      }
      return "";
    },
    initializeCollapsibleItems() {
      for (let index = 0; index < this.exercisesResults.length; index += 1) {
        this.collapsibleItems.push(false);
        this.collapsibleContents.push(null);
      }
      this.isMobileResolution = window.innerWidth < LAPTOP_WIDTH;
    },
    async getItemContent(answerSheetItemId) {
      return ShowAnswerSheetResultsBySubject.getAnswerSheetItemResult({
        answerSheetId: this.answerSheetId,
        answerSheetItemId,
      });
    },
    async updateItems(itemNumber) {
      let indexContentToLoad = null;
      for (let index = 0; index < this.exercisesResults.length; index += 1) {
        if (index === itemNumber) {
          this.$set(this.collapsibleItems, index, !this.collapsibleItems[index]);
          if (this.collapsibleItems[index]) {
            if (this.collapsibleContents[index] === null) {
              indexContentToLoad = index;
              this.$set(this.collapsibleContents, index, "Cargando");
            }
          }
        } else {
          this.$set(this.collapsibleItems, index, false);
        }
      }
      if (indexContentToLoad !== null) {
        const content = await this.getItemContent(this.exercisesResults[indexContentToLoad].id);
        this.$set(this.collapsibleContents, indexContentToLoad, content);
      }
      this.refreshMathJax();
    },
    async getSubjectNameAndImage() {
      this.subjectName = await localForage.getItem("subjectName");
      this.subjectImage = await localForage.getItem("subjectImage");
    },
    emitLoadItem(itemId) {
      this.$emit("load-item", itemId);
    },
    async redirectToHomePage() {
      await this.$router.push({
        name: "Home",
      });
    },
    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          MathJax.Hub.queue.Push(["Typeset", MathJax.Hub]); // eslint-disable-line
        });
      }
    },
    getSavePositionToCutMathjax(text, position) {
      const MATHJAX_OPEN_EXPRESSION = "\\(";
      const MATHJAX_CLOSE_EXPRESSION = "\\)";
      const cutString = text.substring(0, position);
      let openExpressionOccur = 0;
      if (cutString.length > 4) {
        for (let index = 0; index < cutString.length - 1; index += 1) {
          const fragment = cutString[index] + cutString[index + 1];
          if (fragment === MATHJAX_OPEN_EXPRESSION) {
            openExpressionOccur += 1;
          } else if (fragment === MATHJAX_CLOSE_EXPRESSION) {
            openExpressionOccur -= 1;
          }
        }
      }

      if (openExpressionOccur > 0) {
        let indexString = position;
        for (let index = 0; index < openExpressionOccur; index += 1) {
          indexString = text.indexOf(MATHJAX_CLOSE_EXPRESSION, indexString);
        }
        return indexString + 2;
      }
      return position;
    },
    removeHtmlBreakTag(text) {
      const htmlTagIndex = text.indexOf("<br");
      let resultText = text;
      if (htmlTagIndex >= 0) {
        resultText = text.substring(0, htmlTagIndex).concat("...");
      }
      return resultText;
    },

    formatTitleItem(title, itemIndex) {
      const MAX_LENGTH = this.isMobileResolution ? MAX_TITLE_LENGTH_MOBILE : MAX_TITLE_LENGTH;
      let formattedText = "";
      if (this.collapsibleItems[itemIndex]) {
        return title;
      }
      if (title.length < MAX_LENGTH) {
        return this.removeHtmlBreakTag(title);
      }
      const isMathjaxExpression = title.indexOf("\\(") > -1;
      if (isMathjaxExpression) {
        const savePositionToCut = this.getSavePositionToCutMathjax(title, MAX_LENGTH);
        formattedText = title.substring(0, savePositionToCut).concat("...");
      } else {
        formattedText = title.substring(0, MAX_LENGTH).concat("...");
      }
      return this.removeHtmlBreakTag(formattedText);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

img {
  width: 100%;
}

.explanation__button {
  margin: auto;
  width: 95%;
  @include laptop {
    width: 50%;
  }
}

/** @define main-content; */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/** @define answer-sheet-results-by-subject; */
.answer-sheet-results-by-subject {
  width: 100%;
  padding-bottom: 2em;
}

.answer-sheet-results-by-subject__heading {
  @extend %font-body-1;

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: $ipn-color;
}

.answer-sheet-results-by-subject__accept-btn {
  width: 100%;
  margin-top: 2.5em;
  @include laptop {
    width: 60%;
  }
}

/** @define heading; */
.heading__subject-circle-icon {
  margin: 1.2em;
  height: auto;
  color: $black;
  @include laptop {
    margin: 1.75em;
  }
}

/** @define subject-circle-icon; */
.subject-circle-icon__icon {
  width: 100%;
}

/** @define answer-sheet-results-by-subject; */
.answer-sheet-results-by-subject__subjects-results {
  @extend %font-body-1;

  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

/** @define subjects-results; */
.subjects-results__item {
  width: 100%;
}

/** @define item; */
.item__content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.6em;
}

/** @define content; */
.content__button {
  width: 100%;
  margin: auto;
  @include laptop {
    width: 100%;
  }
}

/** @define button; */
.button__image {
  width: 12%;
  @include laptop {
    width: 7%;
  }
}

/** @define exercise; */
.exercise__icon {
  width: 8%;
  padding-right: 0.5em;
  @include laptop {
    width: 2.1%;
  }
}

.exercise__img {
  display: flex;
  justify-content: center;
  padding: 2.25em 0;
}
</style>
