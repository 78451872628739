<template>
  <BaseModal
    :is-close-button-visible="true"
    :is-visible="isVisible"
    style-modifier="transparent"
    @hide-modal="$emit('hide-modal')"
  >
    <template #message>
      <div class="payment_method__instructions m-1 p-2">Selecciona tu método de pago.</div>
      <div class="payment-method__options-container">
        <div class="payment-method__oxxo" data-test-id="buy-with-oxxo-btn" @click="buyWith('oxxo')">
          <OxxoImage /> Oxxo
        </div>
        <div class="payment-method__card" data-test-id="buy-with-card-btn" @click="buyWith('card')">
          <TarjetaImage /> Tarjeta
        </div>
      </div>
    </template>
    <template #callToAction>
      <div class="payment-method"></div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import OxxoImage from "@/assets/oxxo.svg?inline";
import TarjetaImage from "@/assets/tarjeta.svg?inline";

export default {
  name: "PaymentMethodModal",
  components: { BaseModal, OxxoImage, TarjetaImage },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    buyWith(method) {
      this.$emit("buy-with", method);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_spacing.scss";
@import "~@/scss/_colors.scss";

.payment-method {
  display: flex;
}

.payment-method__instructions {
  @extend %font-section-title-2;
}

.payment-method__options-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-top: 1.5em;
}

.payment-method__oxxo {
  width: 25%;
  &:hover {
    cursor: pointer;
    top: 1em;
    opacity: 0.8;
  }
  &:active {
    transform: scale(1.07);
  }
}

.payment-method__card {
  width: 22%;
  &:hover {
    cursor: pointer;
    top: 1em;
    opacity: 0.8;
  }
  &:active {
    transform: scale(1.07);
  }
}
</style>
