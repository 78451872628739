import {
  StartGeneralExamService,
  StartGeneralExamServiceRequest,
} from "@/use_cases/show_subjects/StartGeneralExamService";
import { GetSubjectsService, GetSubjectsServiceRequest } from "@/use_cases/show_subjects/GetSubjectsService";
import {
  StartSubjectExamService,
  StartSubjectExamServiceRequest,
} from "@/use_cases/show_subjects/StartSubjectExamService";

const GetSubjectResponse = function constructor({ id, name, imageUrl }) {
  this.id = id;
  this.name = name;
  this.imageUrl = imageUrl;
};

const getSubjects = async ({ courseProgressId }) => {
  const getSubjectsServiceRequest = new GetSubjectsServiceRequest({
    courseProgressId,
  });
  const getSubjectsService = new GetSubjectsService(getSubjectsServiceRequest);
  const serviceResponse = await getSubjectsService.do();
  return serviceResponse.map((responseData) => new GetSubjectResponse(responseData));
};

const StartGeneralExamResponse = ({ answerSheetId, currentAnswerSheetItemId, isAnswerSheetStarted }) => ({
  answerSheetId,
  currentAnswerSheetItemId,
  isAnswerSheetStarted,
});

const startGeneralExam = async ({ courseProgressId, numberOfExercises }) => {
  const startGeneralExamServiceRequest = StartGeneralExamServiceRequest({
    courseProgressId,
    numberOfExercises,
  });
  const startGeneralExamService = new StartGeneralExamService(startGeneralExamServiceRequest);
  return StartGeneralExamResponse(await startGeneralExamService.do());
};

const StartSubjectExamResponse = ({ answerSheetId, currentAnswerSheetItemId, isAnswerSheetStarted }) => ({
  answerSheetId,
  currentAnswerSheetItemId,
  isAnswerSheetStarted,
});

const startSubjectExam = async ({ courseProgressId, numberOfExercises, subjectId }) => {
  const startSubjectExamServiceRequest = StartSubjectExamServiceRequest({
    courseProgressId,
    numberOfExercises,
    subjectId,
  });
  const startSubjectExamService = new StartSubjectExamService(startSubjectExamServiceRequest);
  return StartSubjectExamResponse(await startSubjectExamService.do());
};

const ShowSubjects = { getSubjects, startGeneralExam, startSubjectExam };

export default ShowSubjects;
