<template>
  <router-link class="title-bar__coins" :to="{ name: 'ShowProductsPage' }">
    <CoinsImage class="coins__img" />
    <span class="coins__amount" data-test-id="coins__amount">
      {{ coinsBalance }}
    </span>
  </router-link>
</template>

<script>
// eslint-disable-next-line import/extensions
import CoinsImage from "@/assets/coins.svg?inline";
import StudentCoins from "@/use_cases/show_student_coins/StudentCoins";

export default {
  name: "StudentCoinsView",

  components: {
    CoinsImage,
  },
  data() {
    return {
      coinsBalance: 0,
    };
  },
  watch: {
    "$route.path": {
      async handler() {
        this.loadBalance();
      },
    },
  },
  methods: {
    loadBalance() {
      if (this.isAStudentPath()) {
        this.loadStoredStudentBalance();
        this.requestStudentBalance();
      }
    },
    async loadStoredStudentBalance() {
      const storedStudendBalance = await StudentCoins.StudentStoredBalance();
      this.setStudentBalance(storedStudendBalance);
    },
    async requestStudentBalance() {
      const requestedStudentBalance = await StudentCoins.GetStudentBalance();
      this.setStudentBalance(requestedStudentBalance);
    },
    setStudentBalance({ coinsBalance }) {
      this.coinsBalance = coinsBalance;
    },
    isAStudentPath() {
      const { path } = this.$route;
      if (path.length === 2 && path.substr(0, 2) === "/s") {
        return true;
      }
      return path.length > 2 && path.substr(0, 3) === "/s/";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
a:link {
  color: white;
  text-decoration: none;
}
a:visited {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
  top: 1em;
  opacity: 0.5;
}
a:active {
  transform: scale(1.4);
}
.title-bar__coins {
  @extend %font-coins;

  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    top: 1em;
    opacity: 0.5;
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
}

/** @define coins */
.coins__img {
  width: 40px;
}

.coins__amount {
  padding-left: 7%;
}
</style>
