<template>
  <div class="products">
    <div class="products__list" data-test-id="products-list">
      <div
        v-for="product in products"
        :key="product.id"
        :class="{
          products__list_3months: product.productId === THREE_MONTHS,
          products__list_6months: product.productId === SIX_MONTHS || product.productId === SIX_MONTHS_DISCOUNT,
          products__list_coins_bundle: product.productId === COINS_BUNDLE,
        }"
      >
        <SubscriptionCard
          v-if="product.productId === THREE_MONTHS"
          :data-test-id="`product-card-${product.productId}`"
          :product="product"
          style-modifier="primary"
          class="subscription__product_3"
          @open-payment-method-modal="openPaymentMethodModal"
        />
        <SubscriptionCard
          v-if="product.productId === SIX_MONTHS && minutesLeft <= 0"
          :data-test-id="`product-card-${product.productId}`"
          :product="product"
          style-modifier="secondary"
          class="subscription__product_6"
          @open-payment-method-modal="openPaymentMethodModal"
        />
        <SubscriptionCardDiscount
          v-if="product.productId === SIX_MONTHS_DISCOUNT && minutesLeft > 0"
          :data-test-id="`product-card-${product.productId}`"
          :product="product"
          :minutes-left="minutesLeft"
          style-modifier="secondary"
          class="subscription__product_6_discount"
          @open-payment-method-modal="openPaymentMethodModal"
          @time-out="timeOut"
        />
        <CoinsBundleCard
          v-if="product.productId === COINS_BUNDLE"
          :data-test-id="`product-card-${product.productId}`"
          :product="product"
          class="coins-bundle__product"
          @open-payment-method-modal="openPaymentMethodModal"
        />
      </div>
      <div class="products__siderbar">
        <ContentSidebarLayout />
      </div>
      <div class="products__info">
        <p class="products__info-text">
          Metodos de pago <br />
          - Tarjetas de credito debito <br />
          - Oxxo
        </p>
      </div>
    </div>
    <div>
      <PaymentMethodModal
        data-test-id="payment-method-modal"
        :is-visible="isPaymentMethodModalOpen"
        @hide-modal="closePaymentMethodModal"
        @buy-with="buyWith"
      />
      <OxxoInstructionsModal
        data-test-id="oxxo-instructions-modal"
        :oxxo-payment-info="oxxoPaymentInfo"
        :is-visible="isOxxoInstructionsModalOpen"
        @hide-modal="closeOxxoInstructionsModal"
      />
      <BankCardFormModal
        :is-visible="isBankCardFormModalOpen"
        data-test-id="bank-card-form-modal"
        :product="selectedProduct"
        @hide-modal="closeBankCardFormModal"
      />
    </div>
  </div>
</template>

<script>
import localForage from "localforage";
import ShowProducts from "@/use_cases/show_products/ShowProducts";
import BuyProduct from "@/use_cases/buy_product/BuyProduct";
import SubscriptionCard from "../buy_product/SubscriptionCard.vue";
import CoinsBundleCard from "../buy_product/CoinsBundleCard.vue";
import PaymentMethodModal from "../buy_product/PaymentMethodModal.vue";
import OxxoInstructionsModal from "../buy_product/OxxoInstructionsModal.vue";
import BankCardFormModal from "../buy_product/BankCardFormModal.vue";
import ContentSidebarLayout from "../../components/ContentSidebarLayout.vue";
import SubscriptionCardDiscount from "../buy_product/SubscriptionCardDiscount.vue";

const COINS_BUNDLE = 1;
const THREE_MONTHS = 2;
const SIX_MONTHS = 3;
const SIX_MONTHS_DISCOUNT = 37;

export default {
  name: "ShowProductsPage",
  components: {
    ContentSidebarLayout,
    SubscriptionCard,
    CoinsBundleCard,
    PaymentMethodModal,
    OxxoInstructionsModal,
    BankCardFormModal,
    SubscriptionCardDiscount,
  },

  data() {
    return {
      /**
       * @typedef Product
       * @type {object}
       * @property {number} productId
       * @property {string} name
       * @property {string} description
       * @property {number} price
       * @property {string} image
       * @property {string} type
       */

      /** @type {Product} * */
      selectedProduct: {},

      /** @type {Product[]} * */
      products: [],

      isPaymentMethodModalOpen: false,
      isOxxoInstructionsModalOpen: false,
      isBankCardFormModalOpen: false,

      oxxoPaymentInfo: {},
      bankPaymentInfo: {},

      minutesLeft: 0,
      initialTime: null,
      promotionTime: null,
    };
  },

  async created() {
    this.COINS_BUNDLE = COINS_BUNDLE;
    this.THREE_MONTHS = THREE_MONTHS;
    this.SIX_MONTHS = SIX_MONTHS;
    this.SIX_MONTHS_DISCOUNT = SIX_MONTHS_DISCOUNT;
  },

  async mounted() {
    await this.requestShowProductsService();
    this.initialTime = await localForage.getItem("initialTime");
    this.promotionTime = await localForage.getItem("promotionTime");
    const now = new Date().getTime();
    if (this.promotionTime != null) {
      this.minutesLeft = (this.promotionTime - Math.round((now - this.initialTime) / 1000)) / 60;
    }
  },

  methods: {
    async timeOut() {
      this.minutesLeft = 0;
    },
    async requestShowProductsService() {
      this.products = await ShowProducts.getProducts();
      this.products.push({
        productId: 37,
        name: "6 meses de Ingresapp Premium",
        description:
          // eslint-disable-next-line max-len
          "Acceso ilimitado durante seis meses a toda las funciones de Ingresapp. Recibiras 1 millón de monedas para que las uses como prefieras.",
        price: 159,
        image: "https://ingresapp-api-new.s3.amazonaws.com/media/treasure.png",
        type: "coins_bundle",
      });
      this.$emit("load-finished");
    },
    openPaymentMethodModal(product) {
      this.selectedProduct = product;
      this.isPaymentMethodModalOpen = true;
    },
    closePaymentMethodModal() {
      this.isPaymentMethodModalOpen = false;
    },
    closeBankCardFormModal() {
      this.isBankCardFormModalOpen = false;
    },
    closeOxxoInstructionsModal() {
      this.isOxxoInstructionsModalOpen = false;
    },
    buyWith(method) {
      switch (method) {
        case "oxxo":
          this.buyWithOxxo();
          break;
        case "card":
          this.buyWithCard();
          break;
      }
    },
    async buyWithCard() {
      this.isBankCardFormModalOpen = true;
    },
    async buyWithOxxo() {
      const { productId } = this.selectedProduct;
      this.oxxoPaymentInfo = await BuyProduct.BuyProductWithOxxo({ productId });
      this.isOxxoInstructionsModalOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_resolutions.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define products */
.products {
  width: 100%;
}

.products__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20%;
  @include laptop {
    display: grid;
    grid-template-columns: [x0] 1% [x1] 30% [x2] 5% [x3] 30% [x4] 5% [x5] 27% [x6] 2% [x7];
    grid-template-rows: [y0] 30% [y1] 30% [y2] 30% [y3] 10% [y4];
    padding-top: 1%;
  }
}

.products__list_3months {
  order: 1;
  @include laptop {
    margin-top: 1em;
    grid-column: x1 / x4;
  }
}

.products__list_6months {
  order: 2;
  @include laptop {
    margin-top: 1em;
    grid-row: y1;
    grid-column: x1;
  }
}

.products__list_coins_bundle {
  order: 3;
  @include laptop {
    margin-top: 1em;
    grid-row: y1;
    grid-column: x3;
  }
}

.products__siderbar {
  visibility: hidden;

  @include laptop {
    visibility: visible;
    grid-column: x5;
    grid-row: y0 / y4;
  }
}

.products__info {
  order: 4;
  text-align: center;

  @include laptop {
    grid-row: y4;
    grid-column: x1 / x4;
  }
}

.products__info-text {
  @extend %font-body-1;
}

/** @define subscription */
.subscription__product_3 {
  @include laptop {
    width: 100%;
  }
}

.subscription__product_6 {
  @include laptop {
    width: 100%;
  }
}

.subscription__product_6_discount {
  @include laptop {
    width: 100%;
  }
}

/** @define coins-bundle; */
.coins-bundle__product {
  @include laptop {
    width: 100%;
  }
}
</style>
