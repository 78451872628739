<template>
  <div>
    <div class="container-text">
      <p class="course-text">Mis Cursos</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowCoursesPage",
  data() {
    return {
      msj: "¡Hola Mundo!",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_resolutions.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

.container-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}
.course-text {
  color: $ipn-color;
  @extend %font-title-1;
  @include laptop {
    visibility: hidden;
  }
}

.course-add {
  cursor: pointer;
  @include laptop {
    visibility: hidden;
  }
}
</style>
