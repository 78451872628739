<template>
  <div class="logo-text">
    <span class="logo-text__text">
      Ingresapp
    </span>
  </div>
</template>

<script>
export default {
  name: "LogoText",
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

$font-logo-text-family: "Avenir-Book";
$font-logo-text-size: 1.2rem;
$font-logo-text-size--laptop: 1.4rem;
$font-logo-text-weight: $font-weight-normal;
%font-logo-text {
  font-family: $font-logo-text-family;
  font-size: $font-logo-text-size;
  font-weight: $font-logo-text-weight;

  @media (min-width: $laptop-width) {
    font-size: $font-logo-text-size--laptop;
  }
}

/** @define logo-text */
.logo-text__text {
  @extend %font-logo-text;
}
</style>
