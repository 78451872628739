<template>
  <div>
    <div :class="'base-input-text base-input-text--' + styleModifier">
      <label>
        <input
          :class="'base-input-text__input base-input-text__input--' + styleModifier"
          :minlength="minLength"
          :placeholder="placeholder"
          :required="required"
          :type="type"
          :value="value"
          :autofocus="autofocus"
          @focusout="validateContent"
          @input="emitInputEvent"
        />
      </label>
    </div>
    <span v-show="isErrorMessageVisible" class="error-message" data-test-id="error-message">
      {{ errorMessage }}
    </span>
    <span
      v-for="validationErrorMessage in validationErrorMessages"
      :key="validationErrorMessage"
      class="error-message"
      data-test-id="validation-error-message"
      >{{ validationErrorMessage }}</span
    >
  </div>
</template>

<script>
import Validatable from "../../mixins/Validatable.vue";

export default {
  name: "BaseInputText",
  mixins: [Validatable],
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    styleModifier: {
      type: String,
      default: "black",
    },
    minLength: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
      validator: (prop) => ["text", "password", "email"].includes(prop),
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    isErrorMessageVisible: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  methods: {
    emitInputEvent(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define base-input-text */
.base-input-text {
  padding: 0.5em;
  width: auto;
  border-bottom: 0.1em solid $black;
}

.base-input-text__input {
  @extend %font-body-2;

  background: transparent;
  border: 0;
  width: 100%;
}

.base-input-text--black {
  border-bottom: 0.1em solid $black;
}

.base-input-text--white {
  border-bottom: 0.1em solid $white;
}

.base-input-text__input--black {
  color: $black;
}

.base-input-text__input--white {
  color: $white;
}

.base-input-text__input--white::placeholder {
  color: $white;
}

/** @define error-message */
.error-message {
  @extend %font-body-2;

  color: $error-color;
  font-weight: bold;
  padding-top: 1em;
}
</style>
