<template>
  <div class="explanation-page">
    <div class="explanation-page__previous-btn">
      <BaseButton
        style-modifier="text"
        data-test-id="return-button"
        class="previous-btn__btn"
        @click="returnToShowAnswerSheetResultsBySubjectPage"
      >
        <PreviousButtonArrowImage class="btn__img" />
        REGRESAR
      </BaseButton>
    </div>
    <ContentSidebarLayout>
      <div v-if="explanation" slot="main-content" class="main-content">
        <div class="main-content__exercise">
          <div class="exercise__title">
            EJERCICIO
          </div>
          <div class="exercise__content" data-test-id="exercise-content">
            <ExerciseText data-test-id="exercise-text">
              <span>{{ explanation.exerciseNumber }}. </span>
              <span v-html="explanation.exerciseText"></span>
              <div v-if="explanation.exerciseImage" class="content__img" data-test-id="exercise-img">
                <img :src="explanation.exerciseImage" />
              </div>
            </ExerciseText>
          </div>
        </div>
        <div class="main-content__explanation">
          <div class="explanation__title">
            EXPLICACIÓN
          </div>
          <div class="explanation__content" data-test-id="explanation-content">
            <span v-html="explanation.explanationText"></span>
            <div v-if="explanation.explanationImage" class="content__img" data-test-id="explanation-img">
              <img :src="explanation.explanationImage" />
            </div>
          </div>
        </div>
        <div class="main-content__correct-answer">
          <div class="correct-answer__title" data-test-id="correct-answer-title">
            <CorrectImage class="title__icon" />
            <span>RESPUESTA CORRECTA</span>
          </div>
          <div class="correct-answer__content" data-test-id="correct-answer-content">
            <span v-html="explanation.correctAnswerText"></span>
            <div v-if="explanation.correctAnswerImage" class="content__img" data-test-id="correct-answer-img">
              <img :src="explanation.correctAnswerImage" />
            </div>
          </div>
        </div>
        <div class="main-content__selected-answer">
          <div class="selected-answer__title" data-test-id="selected-answer-title">
            <CorrectImage v-if="explanation.isCorrect" class="title__icon" data-test-id="correct-img" />
            <IncorrectImage v-else class="title__icon" data-test-id="incorrect-image" />
            <span>RESPUESTA SELECCIONADA</span>
          </div>
          <div class="selected-answer__content" data-test-id="selected-answer-content">
            <span v-html="explanation.selectedAnswerText"></span>
            <div v-if="explanation.selectedAnswerImage" class="content__img" data-test-id="selected-answer-img">
              <img :src="explanation.selectedAnswerImage" />
            </div>
          </div>
        </div>
      </div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import ShowExplanation from "@/use_cases/show_explanation/ShowExplanation";
import ExerciseText from "@/components/exam_exercise/ExerciseText.vue";
import CorrectImage from "@/assets/correct.svg?inline";
import IncorrectImage from "@/assets/incorrect.svg?inline";
import BaseButton from "@/components/base_button/BaseButton.vue";
import PreviousButtonArrowImage from "@/assets/collapse-arrow.svg?inline";
import localForage from "localforage";

export default {
  name: "ExplanationPage",
  components: {
    ContentSidebarLayout,
    ExerciseText,
    CorrectImage,
    IncorrectImage,
    BaseButton,
    PreviousButtonArrowImage,
  },
  props: {
    answerSheetId: {
      type: String,
      required: true,
    },
    answerSheetItemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      explanation: null,
    };
  },
  async mounted() {
    await this.getExplanation();
    await this.refreshMathJax();
    this.$emit("load-finished");
  },
  methods: {
    async getExplanation() {
      this.explanation = await ShowExplanation.getExplanation({ answerSheetItemId: this.answerSheetItemId });
    },
    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          MathJax.Hub.queue.Push(["Typeset", MathJax.Hub]); // eslint-disable-line
        });
      }
    },
    async returnToShowAnswerSheetResultsBySubjectPage() {
      const subjectId = await localForage.getItem("subjectId");
      await this.$router.push({
        name: "AnswerSheetResultsBySubjectPage",
        params: { answerSheetId: this.answerSheetId, subjectId },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

/** @define explanation-page; */
.explanation-page {
  display: flex;
  width: 100%;
  padding-top: 3em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.explanation-page__previous-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 65%;
}

/** @define previous-btn; */
.previous-btn__btn {
  color: $primary-color;
  width: 80%;
  height: 100%;
  margin-left: -1em;
  @include laptop {
    margin-left: -0.1em;
    width: 30%;
  }
}

/** @define explanation-page; weak */
.explanation-page__previous-btn > button {
  text-align: left;
}

/** @define main-content; weak */
.main-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  justify-content: left;
  padding-top: 2em;
  padding-left: 2em;
  padding-bottom: 5em;
  @include laptop {
    padding-top: 5em;
    padding-left: 5em;
  }
}

/** @define title; */
.title {
  @extend %font-title-2;

  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  color: $ipn-color;
  width: 100%;
  padding-bottom: 1em;
}
.title__icon {
  width: 10%;
  padding-right: 0.5em;
  @include laptop {
    width: 4.5%;
  }
}

/** @define content; */
.content {
  @extend %font-body-1;

  width: 94%;
  padding-bottom: 1em;
  padding-left: 0.5em;
  @include laptop {
    padding-left: 2em;
    width: 100%;
  }
}

/** @define exercise; */
.exercise__title {
  @extend .title;
}

.exercise__content {
  @extend .content;
}

/** @define content; */
.content__img {
  display: flex;
  justify-content: center;
  padding: 2.25em 0;
}

/** @define explanation; */
.explanation__title {
  @extend .title;
}

.explanation__content {
  @extend .content;
}

/** @define correct-answer; */
.correct-answer__title {
  @extend %font-title-2;

  display: flex;
  justify-content: left;
  color: $ipn-color;
  width: 100%;
  padding-bottom: 1em;
}

.correct-answer__content {
  @extend .content;
}

/** @define selected-answer; */
.selected-answer__title {
  @extend %font-title-2;

  display: flex;
  justify-content: left;
  color: $ipn-color;
  width: 100%;
  padding-bottom: 1em;
}

.selected-answer__content {
  @extend .content;
}

/** @define btn; weak */
.btn__img {
  transform: rotate(90deg);
  width: 8%;
  @include laptop {
    width: 6%;
  }
}

.btn__img > path {
  fill: $primary-color;
}
</style>
