const OK = 200;
const CREATED = 201;
const BAD_REQUEST = 400;
const UNAUTHORIZED = 401;
const PAYMENT_REQUIRED = 402;
const CONFLICT = 409;

const HttpStatusCodes = { OK, CREATED, BAD_REQUEST, UNAUTHORIZED, PAYMENT_REQUIRED, CONFLICT };

export default HttpStatusCodes;
