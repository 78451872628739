<template>
  <div class="demo-successfully-completed">
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <BaseContainer>
          <div class="main-content__coin-claim-reward">
            <CoinClaimRewardImage />
          </div>

          <div class="main-content__title">
            <p>
              ¡CORRECTO!
            </p>
          </div>

          <div class="main-content__caption">
            <p>
              Reclama tu premio llenando la siguiente información para personalizar Ingresapp para ti.
            </p>
          </div>

          <div class="main-content__get-reward-btn">
            <BaseButton style-modifier="secondary" @click="$router.push({ name: 'SelectMajorPage' })">
              Reclamar Premio
            </BaseButton>
          </div>
        </BaseContainer>
      </div>
      <div slot="sidebar"></div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import CoinClaimRewardImage from "@/assets/coin-claim-reward.svg?inline";
import BaseButton from "@/components/base_button/BaseButton.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";

export default {
  name: "DemoSuccessfullyCompleted",
  components: {
    BaseContainer,
    BaseButton,
    CoinClaimRewardImage,
    ContentSidebarLayout,
  },

  mounted() {
    this.$emit("load-finished");
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define demo-successfully-completed; weak */
.demo-successfully-completed {
  display: flex;
  flex-direction: row;
}

/** @define main-content; */
.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.main-content__coin-claim-reward {
  width: 100%;
  padding-top: 4em;
  @include laptop {
    width: 50%;
    margin: 1.5em;
  }
}

.main-content__get-reward-btn {
  flex-grow: 2;
  width: 100%;
  margin: 1em;

  @include laptop {
    width: 70%;
  }
}

.main-content__title {
  @extend %font-title-1;

  text-align: center;
  color: $ipn-color;
}

.main-content__caption {
  @extend %font-body-1;

  text-align: center;
  flex-grow: 1;
}
</style>
