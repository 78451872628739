<template>
  <div :class="'circle-letter-icon--' + style" data-test-id="circle-letter-icon" @click="emitClickEvent">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: "QuestionCircleIcon",
  props: {
    value: {
      type: Number,
      required: true,
    },
    isAnswered: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    style() {
      return this.isAnswered ? "answered-style" : "unanswered-style";
    },
  },
  methods: {
    emitClickEvent(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define circle-letter-icon; */
.circle-letter-icon {
  @extend %font-subtitle-1;

  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  color: $white;
  border-color: $white;
  justify-content: center;
  border-width: 0.05em;
  border-style: solid;
  cursor: pointer;
}

.circle-letter-icon--answered-style {
  @extend .circle-letter-icon;

  background-color: $secondary-color;
  &:hover {
    background-color: $secondary-color--hover;
  }
}

.circle-letter-icon--unanswered-style {
  @extend .circle-letter-icon;

  background-color: transparent;
  &:hover {
    background-color: $overlay-color--hover;
  }
}
</style>
