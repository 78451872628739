<template>
  <div class="answer-sheet-results-summary">
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <BaseContainer>
          <h1 class="answer-sheet-results-summary__heading">Resultados</h1>

          <TimeAndCorrectAnswersComponent
            style-modifier="desktop"
            :number-of-correct-answers="numberOfCorrectAnswers"
            :number-of-exercises="numberOfExercises"
            :total-time="totalTime"
          />

          <div class="answer-sheet-results-summary__subjects_results">
            <SubjectResultsSummaryComponent
              v-for="subjectResults in subjectsResults"
              :key="subjectResults.id"
              :subject-id="subjectResults.id"
              :subject-name="subjectResults.name"
              :subject-image-url="subjectResults.imageUrl"
              :number-of-correct-answers="subjectResults.numberOfCorrectAnswers"
              :number-of-exercises="subjectResults.numberOfExercises"
              :data-test-id="`subject-results-summary-${subjectResults.id}`"
              @click-on-subject-results-summary="redirectToAnswerSheetResultsBySubjectPage(subjectResults)"
            />
          </div>

          <TimeAndCorrectAnswersComponent
            style-modifier="mobile"
            :number-of-correct-answers="numberOfCorrectAnswers"
            :number-of-exercises="numberOfExercises"
            :total-time="totalTime"
          />

          <div class="answer-sheet-results-summary__accept-btn">
            <router-link :to="{ name: 'Home' }">
              <BaseButton style-modifier="secondary" data-test-id="accept-button">
                Aceptar
              </BaseButton>
            </router-link>
          </div>
        </BaseContainer>
      </div>

      <div slot="sidebar"></div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import localForage from "localforage";
import ShowAnswerSheetResultsSummary from "@/use_cases/show_answer_sheet_results_summary/ShowAnswerSheetResultsSummary";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
// eslint-disable-next-line max-len
import SubjectResultsSummaryComponent from "@/use_cases/show_answer_sheet_results_summary/SubjectResultsSummaryComponent.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
// eslint-disable-next-line max-len
import TimeAndCorrectAnswersComponent from "@/use_cases/show_answer_sheet_results_summary/TimeAndCorrectAnswersComponent.vue";

export default {
  name: "AnswerSheetResultsSummaryPage",
  components: {
    TimeAndCorrectAnswersComponent,
    BaseContainer,
    SubjectResultsSummaryComponent,
    ContentSidebarLayout,
    BaseButton,
  },
  props: {
    answerSheetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      numberOfExercises: 0,
      numberOfCorrectAnswers: 0,
      spentTimeInSeconds: 0,
      /** @type {{
       * id: number, name: string, imageUrl: string, numberOfExercises: number, numberOfCorrectAnswers: number
       * }[]} * */
      subjectsResults: [],
    };
  },
  computed: {
    totalTime() {
      const seconds = Math.floor(this.spentTimeInSeconds % 60);
      const minutes = Math.floor((this.spentTimeInSeconds / 60) % 60);
      const hours = Math.floor((this.spentTimeInSeconds / (60 * 60)) % 24);

      return `${hours}:${minutes}:${seconds}`;
    },
  },
  async mounted() {
    ({
      numberOfExercises: this.numberOfExercises,
      numberOfCorrectAnswers: this.numberOfCorrectAnswers,
      spentTimeInSeconds: this.spentTimeInSeconds,
      subjectsResults: this.subjectsResults,
    } = await ShowAnswerSheetResultsSummary.getAnswerSheetResultsSummary({
      answerSheetId: this.answerSheetId,
    }));

    this.$emit("load-finished");
  },
  methods: {
    async redirectToAnswerSheetResultsBySubjectPage(subjectResult) {
      await localForage.setItem("subjectImage", subjectResult.imageUrl);
      await localForage.setItem("subjectName", subjectResult.name);

      await this.$router.push({
        name: "AnswerSheetResultsBySubjectPage",
        params: { answerSheetId: this.answerSheetId, subjectId: subjectResult.id },
      });
    },
    async redirectToHomePage() {
      await this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define main-content; */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/** @define answer-sheet-results-summary; */
.answer-sheet-results-summary {
  width: 100%;
  padding-bottom: 2em;
}

.answer-sheet-results-summary__heading {
  @extend %font-title-2;

  text-align: center;
  color: $ipn-color;
}

.answer-sheet-results-summary__subjects_results {
  flex: 1 1 auto;
}

.answer-sheet-results-summary__accept-btn {
  width: 100%;
  margin-top: 2.5em;
}
</style>
