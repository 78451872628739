<template>
  <div class="exercise-text">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ExerciseText",
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";

/** @define exercise-text */
.exercise-text {
  @extend %font-body-1;

  margin-top: 1.5em;
  margin-bottom: 1em;
  text-align: justify;
}
</style>
