<template>
  <div class="base-input-checkbox">
    <input
      id="base-input-checkbox__input"
      type="checkbox"
      class="base-input-checkbox__input"
      :checked="checked"
      @change="emitInputEvent"
    />
    <label for="base-input-checkbox__input" class="base-input-checkbox__text">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseInputCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.value,
    };
  },
  methods: {
    emitInputEvent(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_fonts.scss";
@import "~@/scss/_typography.scss";

/** @define base-input-checkbox */
.base-input-checkbox {
  display: flex;
  justify-content: left;
  flex-direction: row;
}

.base-input-checkbox__input {
  color: white;
  cursor: pointer;
  user-select: none;

  &:checked {
    background: white;
  }
}

.base-input-checkbox__text {
  @extend %font-caption-2;

  cursor: pointer;
  padding-top: 0.15em;
  padding-left: 0.2em;
  user-select: none;
}
</style>
