<template>
  <BaseInputText
    placeholder="Contraseña"
    style-modifier="white"
    :required="true"
    error-message="Contraseña incorrecta"
    :min-length="8"
    :type="inputType"
    :autofocus="autofocus"
    :value="value"
    :is-error-message-visible="isInvalidPasswordMessageVisible"
    @input="emitInputEvent"
  />
</template>

<script>
import BaseInputText from "@/components/base_input_text/BaseInputText.vue";

export default {
  name: "BaseInputPassword",
  components: { BaseInputText },
  props: {
    value: {
      type: String,
      default: "",
    },
    isTextHidden: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    isInvalidPasswordMessageVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputType() {
      return this.isTextHidden ? "password" : "text";
    },
  },
  methods: {
    emitInputEvent(value) {
      this.$emit("input", value);
    },
  },
};
</script>
