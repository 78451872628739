<template>
  <div class="input-dropdown">
    <div class="input-dropdown__input-text">
      <input
        v-model="searchText"
        :placeholder="placeholder"
        :disabled="disabled"
        :loading="loading"
        type="search"
        class="input-text__input"
        @focus="focus"
        @input="inputChange"
        @keydown.enter.prevent="keyEnter"
        @keydown.up.prevent="keyUp"
        @keydown.down.prevent="keyDown"
        @keydown.delete="inputChange"
      />
    </div>
    <div v-if="loading || isItemsListShowed" class="input-dropdown__suggestion-list">
      <span v-if="loading" class="suggestion-list__loader">
        Obteniendo información...
      </span>
      <span v-else-if="searchHasNoResults()" class="suggestion-list__loader">
        Sin resultados
      </span>
      <div v-else-if="isItemsListShowed">
        <div v-for="(group, groupIndex) in groupedItems" :key="groupIndex">
          <div v-if="group.name" class="suggestion-list__group" data-test-id="input-drop-down-group">
            {{ group.name }}
          </div>
          <div v-for="(subgroup, subgroupIndex) in group.subgroups" :key="subgroupIndex">
            <div class="group__subgroup" data-test-id="input-drop-down-subgroup">
              {{ subgroup.name }}
            </div>
            <div
              v-for="(item, itemIndex) in subgroup.items"
              :key="itemIndex"
              :class="
                groupIndex === cursor.group && subgroupIndex === cursor.subgroup && itemIndex === cursor.item
                  ? 'subgroup__item-active'
                  : 'subgroup__item'
              "
              @mouseover="cursor = { group: groupIndex, subgroup: subgroupIndex, item: itemIndex }"
              @click="selectItem(item, subgroup, group)"
            >
              <div :is="itemTemplate" :item="item" data-test-id="input-drop-down-item" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars,import/named
import groupItemsInInputDropDownItems from "@/components/input_dropdown/InputDropdown";

export default {
  name: "InputDropdown",
  props: {
    itemTemplate: {
      type: Object,
      required: true,
    },
    minLength: { type: Number, default: 3 },
    items: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
  },
  data() {
    return {
      searchText: "",
      isItemsListShowed: false,
      cursor: { group: 0, subgroup: 0, item: 0 },
      filteredItems: [],
    };
  },
  computed: {
    filteredItemsLength() {
      return this.filteredItems.length;
    },
    groupedItems() {
      return groupItemsInInputDropDownItems(this.filteredItems);
    },
  },
  watch: {
    filteredItemsLength() {
      this.isItemsListShowed = this.isAbleToShowList();
    },
    items() {
      this.filteredItems = JSON.parse(JSON.stringify(this.items));
      this.isItemsListShowed = this.isAbleToShowList();
    },
    loading() {
      this.filteredItems = JSON.parse(JSON.stringify(this.items));
    },
  },
  mounted() {
    this.filteredItems = JSON.parse(JSON.stringify(this.items));
  },
  methods: {
    normalizeWordInLowerCase(word) {
      return word
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },
    filterList(list, word) {
      const normalizedWord = this.normalizeWordInLowerCase(word);
      return list.filter(
        (item) =>
          this.normalizeWordInLowerCase(item.name).includes(normalizedWord) ||
          this.normalizeWordInLowerCase(item.subgroup).includes(normalizedWord) ||
          this.normalizeWordInLowerCase(item.group).includes(normalizedWord)
      );
    },
    validateInputText(inputText) {
      if (inputText.length > 2) {
        const validator = new RegExp("[a-z|A-Z|0-9]{3}");
        return validator.test(inputText.substring(0, 3));
      }
      return false;
    },
    inputChange() {
      const isTextValid = this.validateInputText(this.searchText);
      this.isItemsListShowed = this.isAbleToShowList();
      this.cursor = { group: 0, subgroup: 0, item: 0 };
      this.$emit("changed", this.searchText, isTextValid);
      if (isTextValid) {
        if (this.items.length > 0) {
          this.filteredItems = JSON.parse(JSON.stringify(this.items));
          if (this.searchText.includes(" ")) {
            const words = this.searchText.split(/(\s+)/);
            for (let index = 0; index < words.length; index += 1) {
              this.filteredItems = this.filterList(this.filteredItems, words[index]);
            }
          } else {
            this.filteredItems = this.filterList(this.items, this.searchText);
          }
        }
      }
    },
    searchHasNoResults() {
      return (this.searchText || "").length >= this.minLength && this.filteredItems.length === 0;
    },
    isAbleToShowList() {
      return (
        (this.searchText || "").length >= this.minLength &&
        this.filteredItems.length > 0 &&
        this.validateInputText(this.searchText)
      );
    },
    focus() {
      this.isItemsListShowed = this.isAbleToShowList();
    },
    selectItem(item, subgroup, group) {
      if (item) {
        this.searchText = `${item.name} ${subgroup.name} ${group.name}`;
        this.$emit("selected", this.searchText, item.id);
      }
      this.isItemsListShowed = false;
    },
    keyUp() {
      this.isItemsListShowed = this.isAbleToShowList();
      if (this.cursor.item > 0) {
        this.cursor.item -= 1;
      } else if (this.cursor.subgroup > 0) {
        this.cursor.subgroup -= 1;
        this.cursor.item = this.groupedItems[this.cursor.group].subgroups[this.cursor.subgroup].items.length - 1;
      } else if (this.cursor.group > 0) {
        this.cursor.group -= 1;
        this.cursor.subgroup = this.groupedItems[this.cursor.group].subgroups.length - 1;
        this.cursor.item = this.groupedItems[this.cursor.group].subgroups[this.cursor.subgroup].items.length - 1;
      }
    },
    keyDown() {
      this.isItemsListShowed = this.isAbleToShowList();
      if (this.cursor.item < this.groupedItems[this.cursor.group].subgroups[this.cursor.subgroup].items.length - 1) {
        this.cursor.item += 1;
      } else if (this.cursor.subgroup < this.groupedItems[this.cursor.group].subgroups.length - 1) {
        this.cursor.subgroup += 1;
        this.cursor.item = 0;
      } else if (this.cursor.group < this.groupedItems.length - 1) {
        this.cursor.group += 1;
        this.cursor.subgroup = 0;
        this.cursor.item = 0;
      }
    },
    keyEnter() {
      const item = this.groupedItems[this.cursor.group].subgroups[this.cursor.subgroup].items[this.cursor.item];
      const subgroup = this.groupedItems[this.cursor.group].subgroups[this.cursor.subgroup];
      const group = this.groupedItems[this.cursor.group];
      if (this.isItemsListShowed && item) {
        this.selectItem(item, subgroup, group);
        this.isItemsListShowed = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define input-text */
.input-text__input {
  @extend %font-form;

  width: 100%;
  border-color: black;
  height: 1.8em;
  border-width: 0 0 0.1em;
}

/** @define input-dropdown */
.input-dropdown {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.input-dropdown__suggestion-list {
  @extend %font-form;

  background: $white;
  border-style: solid;
  border-color: grey;
  border-width: thin;
  max-height: 20em;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 3;
}

/** @define suggestion-list */
.suggestion-list__group {
  font-weight: bold;
  padding-left: 0.3em;
  cursor: default;
  color: $cherry;
}

/** @define group */
.group__subgroup {
  font-weight: bold;
  padding-left: 1em;
  cursor: default;
}

/** @define subgroup */
.subgroup__item {
  padding-left: 2em;
  cursor: pointer;
}

.subgroup__item-active {
  padding-left: 2em;
  background-color: $blue;
  color: white;
  cursor: pointer;
}
</style>
