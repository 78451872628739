<template>
  <div class="circle-icon-progress-bar" @click="emitClickEvent">
    <div class="circle-icon-progress-bar__img">
      <div class="img__empty-progress-bar" />
      <div class="img__progress-bar" :style="progressBarStyle" data-test-id="progress-bar" @click="emitClickEvent">
        <div class="progress-bar__right-side" :style="rightCircleStyle" />
        <div class="progress-bar__left-side" :style="leftCircleStyle" />
      </div>
      <CircleIcon :style-modifier="styleModifier" class="img__circle-icon" data-test-id="circle-icon">
        <slot slot="icon"></slot>
      </CircleIcon>
    </div>
    <div class="circle-icon-progress-bar__caption" data-test-id="circle-icon-progressbar-caption">
      <span>{{ caption }}</span>
    </div>
  </div>
</template>

<script>
import CircleIcon from "@/components/circle_icon/CircleIcon.vue";

export default {
  name: "CircleIconWithProgressBar",
  components: { CircleIcon },
  props: {
    progressPercentage: {
      type: Number,
      required: true,
    },
    styleModifier: {
      type: String,
      required: false,
      default: "",
      validator: (prop) => ["featured", ""].includes(prop),
    },
    caption: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      rightCircleStyle: "",
      leftCircleStyle: "",
      progressBarStyle: "",
    };
  },
  computed: {
    roundedProgressPercentage() {
      return Math.round(this.progressPercentage * 10) / 10;
    },
  },
  mounted() {
    if (this.roundedProgressPercentage <= 50) {
      this.progressBarStyle = "clip-path: inset(0 0 0 3.4em)";
      if (this.roundedProgressPercentage <= 25) {
        this.rightCircleStyle = "clip-path: inset(0 3.4em 3.4em 0);";
      } else {
        this.rightCircleStyle = "clip-path: inset(0 3.4em 0 0);";
      }
      const percentageInDegreesRight = (this.roundedProgressPercentage * 180) / 50;
      this.rightCircleStyle += `-webkit-transform: rotate(${percentageInDegreesRight}deg);`;
    } else {
      this.rightCircleStyle = "clip-path: inset(0 0 0 3.3em);";
      this.leftCircleStyle = "clip-path: inset(0 0 0 3.3em);";
      const percentageInDegreesLeft = ((this.roundedProgressPercentage - 50) * 180) / 50;
      this.leftCircleStyle += `-webkit-transform: rotate(${percentageInDegreesLeft}deg);`;
    }
  },
  methods: {
    emitClickEvent(event) {
      this.$emit("click", event);
    },
    roundPercentageToOneDec(percentage) {
      return Math.round(percentage * 10) / 10;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define circle-icon-progress-bar; */
.circle-icon-progress-bar {
  @extend %font-caption-2;

  width: 6.8em;
  height: 6.8em;
  cursor: pointer;
}

.circle-icon-progress-bar__img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.8em;
  height: 6.8em;
}

.circle-icon-progress-bar__caption {
  @extend %font-caption-2;

  text-align: center;
  width: 100%;
  cursor: pointer;
}

/** @define img; */
.img__circle-icon {
  z-index: 2;
  padding: 0 0.9em;
  height: 5em;
}

.img__progress-bar {
  width: 6.8em;
  height: 6.8em;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img__empty-progress-bar {
  border: 0.9em solid $secondary-overlay-color;
  border-radius: 50%;
  cursor: pointer;
  width: 5em;
  height: 5em;
  position: absolute;
}

/** @define progress-bar; */
.progress-bar__right-side {
  border: 0.9em solid $success-color;
  border-radius: 100%;
  cursor: pointer;
  width: 5em;
  height: 5em;
  position: absolute;
}

.progress-bar__left-side {
  border: 0.9em solid $success-color;
  border-radius: 100%;
  cursor: pointer;
  width: 5em;
  height: 5em;
  position: absolute;
  clip-path: inset(0 3.5em 0 0);
}
</style>
