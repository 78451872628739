<template>
  <div>
    <BaseButton style-modifier="text" data-test-id="log-out-button" @click="$emit('log-out')">
      <div class="log-out-button"><p class="log-out-button__text">Cerrar Sesion</p></div>
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";

export default {
  name: "LogOutButton",
  components: {
    BaseButton,
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

/** @define log-out-button */
.log-out-button {
  height: 2em;
  width: 10em;
  background: $white;
  border: 0.2em;
  border-color: $black-with-transparency;
  border-radius: 0.2em;
  border-style: ridge;
  color: #1a1a1a;
}

.log-out-button__text {
  margin-top: 0.5em;
}
</style>
