<template>
  <div class="sign-up-form">
    <div class="sign-up-form__field">
      <BaseInputPassword
        v-model="password"
        :is-text-hidden="!isPasswordVisible"
        :is-invalid-password-message-visible="isInvalidPasswordMessageVisible"
        error-message="Contraseña incorrecta"
        @keyup.enter.native="signUp"
      />
      <BaseInputCheckbox v-model="isPasswordVisible" class="field__input-check">
        Mostrar contraseña
      </BaseInputCheckbox>
    </div>

    <div class="sign-up-form__field">
      <BaseButton style-modifier="secondary" @click="signUp()">
        Continuar
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
import BaseInputCheckbox from "@/components/base_input_checkbox/BaseInputCheckbox.vue";
import SignUp from "@/use_cases/login_sign_up/SignUp";
import BaseInputPassword from "@/components/base_input_password/BaseInputPassword.vue";

export default {
  name: "SignUpPage",
  components: {
    BaseInputPassword,
    BaseButton,
    BaseInputCheckbox,
  },
  data() {
    return {
      response: "",
      password: "",
      authProvider: "",
      is_signed_up: "",
      isPasswordVisible: false,
      isInvalidPasswordMessageVisible: false,
      signUpUseCaseResponse: "",
    };
  },
  methods: {
    async signUp() {
      try {
        this.signUpUseCaseResponse = await SignUp.signUp(this.password);
        if (this.signUpUseCaseResponse.jwt === null) {
          alert("Hubo un problema en el registro");
        } else {
          await this.$router.push({ name: "DemoPage" });
        }
      } catch (err) {
        console.log(err.response.status);
      }
    },
  },
};
</script>
<style scoped lang="scss">
/** @define sign-up-form */
.sign-up-form {
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
  width: 80%;
}

.sign-up-form__field {
  flex: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 0.6em;
}

/** @define field */
.field__input-check {
  padding-top: 1em;
  padding-bottom: 1em;
}
</style>
