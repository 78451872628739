import localForage from "localforage";
import { GetCoinsBalanceService, GetCoinsBalanceRequest } from "@/use_cases/show_student_coins/StudentCoinsService";

const LOCAL_FORAGE_KEY = "storedStudentCoinsBalance";

const GetStudentCoinsResponse = function constructor({ coinsBalance, isCoinsBundlePurchaseEventPending } = {}) {
  this.coinsBalance = coinsBalance;
  this.isCoinsBundlePurchaseEventPending = isCoinsBundlePurchaseEventPending;
};

const GetStudentBalance = async () => {
  const coinsBalanceService = new GetCoinsBalanceService(GetCoinsBalanceRequest({}));
  const serviceResponse = await coinsBalanceService.do();
  localForage.setItem(LOCAL_FORAGE_KEY, serviceResponse);
  return new GetStudentCoinsResponse(serviceResponse);
};

const StudentStoredBalance = async () => {
  const defaultStoredBalanceObject = { coinsBalance: 0, isCoinsBundlePurchaseEventPending: false };
  try {
    const studentBalance = await localForage.getItem(LOCAL_FORAGE_KEY);
    if (studentBalance !== null) {
      return studentBalance;
    }
    return defaultStoredBalanceObject;
  } catch {
    return defaultStoredBalanceObject;
  }
};

export default { GetStudentBalance, StudentStoredBalance };
