<template>
  <div class="show-simulation-exams">
    <ContentSidebarLayout>
      <div slot="main-content">
        <div class="show-simulation-exams__featured-circle-icons">
          <CircleIcon class="featured-circle-icons__simulation-exam-circle-icon">
            <img
              slot="icon"
              class="circle-icon__img"
              src="../../assets/simulation-exams.png"
              alt="simulation exams icon"
            />
          </CircleIcon>
        </div>

        <span class="show-simulation-exams__title">Exámen de simulación</span>
        <span class="show-simulation-exams__area">{{ simulationExamsArea }}</span>

        <div
          v-for="(simulationExamGroup, index) in groupedSimulationExams"
          :key="index"
          class="show-simulation-exams__group"
        >
          <div class="group__subtitle">{{ simulationExamGroup[0].year }}</div>

          <CircleIconWithProgressBar
            v-for="(simulationExam, simulationExamCounter) in simulationExamGroup"
            :key="simulationExam.simulationExamId"
            :progress-percentage="simulationExam.answerSheetProgress"
            :caption="simulationExam.name"
            class="group__circle-icon"
            :data-test-id="`simulation-exam-icon-${simulationExam.simulationExamId}`"
            @click="onSimulationExamClick(simulationExam)"
          >
            <OneLetterIcon :value="simulationExamCounter + 1" />
          </CircleIconWithProgressBar>
        </div>

        <SimulationExamPriceModal
          v-model="isPriceModalComponentVisible"
          :simulation-exam="selectedSimulationExam"
          data-test-id="simulation-exam-price-modal"
          @continue-with-simulation-exam="startExam"
        />

        <PendingSimulationExamModal
          v-model="isSimulationExamInProgressModalVisible"
          :pending-simulation-exam="pendingSimulationExam"
          data-test-id="pending-simulation-exam-modal"
          @resume-exam="startExam"
        />

        <InsufficientCoinsModalComponent v-model="isInsufficientCoinsModal" data-test-id="insufficient-coins-modal" />
      </div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import ShowSimulationExams from "@/use_cases/show_simulation_exams/ShowSimulationExams";
import OneLetterIcon from "@/components/circle-icon-with-progress-bar/CircleLetterIcon.vue";
import CircleIconWithProgressBar from "@/components/circle-icon-with-progress-bar/CircleIconWithProgressBar.vue";
import CircleIcon from "@/components/circle_icon/CircleIcon.vue";
import SimulationExamPriceModal from "@/use_cases/show_simulation_exams/SimulationExamPriceModalComponent.vue";
import PendingSimulationExamModal from "@/use_cases/show_simulation_exams/PendingSimulationExamModalComponent.vue";
import InsufficientCoinsModalComponent from "@/use_cases/show_subjects/InsufficientCoinsModalComponent.vue";
import InsufficientCoins from "@/exceptions/InsufficientCoins";
import StartSimulationExam from "@/use_cases/show_simulation_exams/StartSimulationExam";
import localForage from "localforage";

export default {
  name: "ShowSimulationExamsPage",
  components: {
    PendingSimulationExamModal,
    SimulationExamPriceModal,
    ContentSidebarLayout,
    CircleIcon,
    OneLetterIcon,
    CircleIconWithProgressBar,
    InsufficientCoinsModalComponent,
  },
  props: {
    courseProgressId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /**
       * @typedef SimulationExam
       * @type {object}
       * @property {string} simulationExamId
       * @property {string} courseName
       * @property {string} releaseDate
       * @property {string} finishedAnswerSheetId
       * @property {string} currentAnswerSheetItemId
       * @property {string} unfinishedAnswerSheetId
       * @property {string} freeReleaseEndDate
       * @property {float} answerSheetProgress
       * @property {number} year
       * @property {string} name
       */

      /** @type {SimulationExam[]} * */
      groupedSimulationExams: [],
      simulationExamsArea: "",
      isPriceModalComponentVisible: false,
      isSimulationExamInProgressModalVisible: false,
      isPendingSimulationExam: false,
      /** @type {SimulationExam} * */
      pendingSimulationExam: {},
      isInsufficientCoinsModal: false,
      /** @type {SimulationExam} * */
      selectedSimulationExam: {},
    };
  },
  async mounted() {
    this.groupedSimulationExams = await ShowSimulationExams.getSimulationExams({
      courseProgressId: this.courseProgressId,
    });

    this.simulationExamsArea = await this.groupedSimulationExams[0][0].courseName;
    for (let groupIndex = 0; groupIndex < this.groupedSimulationExams.length; groupIndex += 1) {
      for (let examIndex = 0; examIndex < this.groupedSimulationExams[groupIndex].length; examIndex += 1) {
        if (this.groupedSimulationExams[groupIndex][examIndex].unfinishedAnswerSheetId !== null) {
          this.isPendingSimulationExam = true;
          this.pendingSimulationExam = this.groupedSimulationExams[groupIndex][examIndex];
        }
      }
    }

    this.$emit("load-finished");
  },
  methods: {
    showInsufficientCoinsModalComponent() {
      this.isInsufficientCoinsModal = true;
    },

    async startExam(simulationExam) {
      try {
        const response = await StartSimulationExam.StartSimulationExam({
          simulationExamId: simulationExam.simulationExamId,
        });

        await localForage.setItem("simulationExamName", `${simulationExam.name} - ${simulationExam.year}`);
        await localForage.setItem("simulationExamArea", this.simulationExamsArea);

        await this.$router.push({
          name: "SimulationExamPage",
          params: {
            simulationExamId: simulationExam.simulationExamId,
            answerSheetId: response.answerSheetId,
            answerSheetItemId: response.currentAnswerSheetItemId,
          },
        });
      } catch (e) {
        if (e instanceof InsufficientCoins) {
          this.showInsufficientCoinsModalComponent();
        }
      }
    },

    async onSimulationExamClick(simulationExam) {
      if (simulationExam.unfinishedAnswerSheetId === null && this.isPendingSimulationExam) {
        this.isSimulationExamInProgressModalVisible = true;
      } else if (!this.isPendingSimulationExam) {
        this.selectedSimulationExam = simulationExam;
        this.isPriceModalComponentVisible = true;
      } else {
        await this.startExam(simulationExam);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

/** @define show-simulation-exams; */
.show-simulation-exams {
  font-size: map-get($font-base, size);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 2.5em;
  padding-bottom: 10rem;
  @include laptop {
    padding-top: 3.5em;
    padding-bottom: 0;
  }
}
.show-simulation-exams__title {
  @extend %font-caption-3;

  display: flex;
  justify-content: center;
  margin-top: 0.6em;
}

.show-simulation-exams__area {
  @extend %font-caption-3;

  color: $primary-color;
  display: flex;
  justify-content: center;
  margin-top: 0.3em;
}

.show-simulation-exams__group {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1.5em;
}

.show-simulation-exams__featured-circle-icons {
  width: 100%;
  display: flex;
  justify-content: center;
}

/** @define group; */
.group__circle-icon {
  margin: 0.6em;
  height: auto;
  position: relative;
  @include laptop {
    margin: 0.8em;
  }
}

.group__subtitle {
  @extend %font-caption-3;

  margin: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
}

/** @define featured-circle-icons; */
.featured-circle-icons__simulation-exam-circle-icon {
  margin: 0 2em;
  height: auto;
  @include laptop {
    margin: 0 6em;
  }
}

/** @define circle-icon; */
.circle-icon__img {
  width: 100%;
}
</style>
