<template>
  <div>
    <BaseButton style-modifier="text" data-test-id="menu-button" @click="$emit('click-log-out-menu')">
      <div class="log-out-menu">...</div>
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";

export default {
  name: "LogOutMenu",
  components: {
    BaseButton,
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

.log-out-menu {
  position: absolute;
  top: 115%;
  left: 63%;
  transform: translate(-50%, -50%) rotate(90deg);
  font-size: 2em;
}
</style>
