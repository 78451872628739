<template>
  <div v-show="isVisible" class="base-modal">
    <div class="base-modal__container">
      <div :class="'base-modal__main-content base-modal__main-content--' + styleModifier">
        <div class="base-modal__img">
          <slot name="image"></slot>
        </div>

        <div :class="'base-modal__message base-modal__message--' + styleModifier">
          <slot class="message_text" name="message"></slot>
        </div>

        <div class="base-modal__call-to-action" data-test-id="base-modal-call-to-action">
          <slot name="callToAction"></slot>
        </div>
      </div>

      <BaseButton
        v-if="isCloseButtonVisible"
        style-modifier="text"
        class="base-modal__close-btn"
        data-test-id="base-modal-close-button"
        @click="emitHideModalEvent"
      >
        <CloseModalButtonImage class="close-btn__img" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
// eslint-disable-next-line import/extensions
import CloseModalButtonImage from "@/assets/close-modal-button.svg?inline";

export default {
  name: "BaseModal",
  components: { BaseButton, CloseModalButtonImage },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isCloseButtonVisible: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    styleModifier: {
      type: String,
      required: true,
      validator: (prop) => ["transparent", "secondary"].includes(prop),
    },
  },
  methods: {
    emitHideModalEvent() {
      this.$emit("hide-modal", false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_fonts.scss";
@import "~@/scss/_typography.scss";

/** @define base-modal */
.base-modal {
  font-size: map-get($font-base, size);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay-color;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.base-modal__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 90%;
}

.base-modal__main-content--secondary {
  background: $secondary-overlay-color;
  padding: 5em 0;
  border-radius: 20px;
}

.base-modal__img {
  display: flex;
  justify-content: center;
  width: 100%;
}

.base-modal__message {
  @extend %font-title-2;

  text-align: center;
}

.base-modal__message--transparent {
  color: $white;
}

.base-modal__message--secondary {
  color: $black;
}

.base-modal__call-to-action {
  width: 100%;
  display: flex;
  justify-content: center;
}

.base-modal__close-btn {
  margin-top: 3.5rem;
  width: 3.5rem;
  height: 3.5rem;
}

/** @define close-btn */
.close-btn__img {
  width: 100%;

  @include laptop {
    width: 100%;
  }
}
</style>
