import localForage from "localforage";
import {
  GetActiveCourseProgressIdService,
  GetActiveCourseProgressIdServiceRequest,
} from "@/use_cases/get_active_course_progress_id/GetActiveCourseProgressIdService";

const GetActiveCourseProgressIdResponse = ({ activeCourseProgressId }) => ({
  activeCourseProgressId,
});

const getActiveCourseProgressId = async () => {
  const activeCourseProgressId = await localForage.getItem("activeCourseProgressId");
  if (activeCourseProgressId) {
    return GetActiveCourseProgressIdResponse({ activeCourseProgressId });
  }
  const serviceRequest = GetActiveCourseProgressIdServiceRequest();
  const service = new GetActiveCourseProgressIdService(serviceRequest);
  const serviceResponse = await service.do();
  await localForage.setItem("activeCourseProgressId", serviceResponse.activeCourseProgressId);

  return GetActiveCourseProgressIdResponse(serviceResponse);
};

const GetActiveCourseProgressId = { getActiveCourseProgressId };

export default GetActiveCourseProgressId;
