<template>
  <div class="subject-results-summary" @click="emitClickOnSubjectResultsSummary(subjectId)">
    <CircleIcon>
      <img slot="icon" class="subject-results-summary__img" :src="subjectImageUrl" :alt="`${subjectName} image`" />
    </CircleIcon>
    <span> {{ subjectName }} </span>
    <span>{{ numberOfCorrectAnswers }}/{{ numberOfExercises }} </span>
    <NextArrowImage class="next-arrow__img" />
  </div>
</template>
<script>
import CircleIcon from "@/components/circle_icon/CircleIcon.vue";
// eslint-disable-next-line import/extensions
import NextArrowImage from "@/assets/collapse-arrow.svg?inline";

export default {
  name: "SubjectResultsSummaryComponent",
  components: {
    CircleIcon,
    NextArrowImage,
  },
  props: {
    subjectId: {
      type: Number,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
    subjectImageUrl: {
      type: String,
      required: true,
    },
    numberOfExercises: {
      type: Number,
      required: true,
    },
    numberOfCorrectAnswers: {
      type: Number,
      required: true,
    },
  },
  methods: {
    emitClickOnSubjectResultsSummary(subjectId) {
      this.$emit("click-on-subject-results-summary", subjectId);
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define subject-results-summary; */
.subject-results-summary {
  @extend %font-body-1;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 1.25em;
}

.subject-results-summary__img {
  width: 100%;
}

/** @define next-arrow; weak */

.next-arrow__img {
  transform: rotate(270deg);
  width: 8%;
  @include laptop {
    width: 6%;
  }
}

.next-arrow__img > path {
  fill: $primary-color;
}
</style>
