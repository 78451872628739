<template>
  <div>
    <div class="form-container">
      <div class="form-container__field">
        <BaseInputText
          ref="email"
          v-model="email"
          :autofocus="true"
          placeholder="Correo"
          style-modifier="white"
          :validations="rules['email']"
          @keyup.enter.native="continueWithEmail()"
        />
      </div>
      <div class="form-container__field">
        <BaseButton style-modifier="secondary" @click="continueWithEmail()">
          Continuar
        </BaseButton>
      </div>
    </div>
    <div class="form-container">
      <div class="form-container__field">
        <ContinueWithSocialAccountButton auth-provider="facebook" data-test-id="continue-with-facebook-btn" />
      </div>
      <div class="form-container__field">
        <ContinueWithSocialAccountButton auth-provider="google" data-test-id="continue-with-google-btn" />
      </div>
    </div>

    <EmailRegisteredWithSocialAccountModal
      v-model="isEmailRegisteredWithSocialAccountModalVisible"
      data-test-id="email-registered-with-social-account-modal"
      :auth-provider="socialAuthProvider"
    />
  </div>
</template>

<script>
import localForage from "localforage";
// eslint-disable-next-line max-len
import EmailRegisteredWithSocialAccountModal from "@/components/email_registered_with_social_account_modal/EmailRegisteredWithSocialAccountModal.vue";
import BaseInputText from "@/components/base_input_text/BaseInputText.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import Validator from "@/Validator";
import Form from "@/mixins/Form.vue";
import LoginSignUp from "@/use_cases/login_sign_up/LoginSignUp";
// eslint-disable-next-line max-len
import ContinueWithSocialAccountButton from "@/components/continue_with_social_account_button/ContinueWithSocialAccountButton.vue";

const invalidEmailErrorMessage = "El correo no es válido.";
export default {
  name: "LoginSignUpPage",
  components: {
    EmailRegisteredWithSocialAccountModal,
    BaseInputText,
    BaseButton,
    ContinueWithSocialAccountButton,
  },
  mixins: [Form],
  data() {
    return {
      email: "",
      socialAuthProvider: "facebook",
      isEmailRegisteredWithSocialAccountModalVisible: false,
      rules: {
        email: [{ rule: Validator.isEmailValid, errorMessage: invalidEmailErrorMessage }],
      },
    };
  },
  created() {
    this.form = {
      email: this.email,
    };
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async continueWithEmail() {
      if (this.isFormValid()) {
        const [response] = await Promise.all([
          LoginSignUp.validateIfEmailIsAlreadyRegistered({
            email: this.email,
          }),
          localForage.setItem("email", this.email),
        ]);

        if (response.isRegistered === false) {
          return this.$router.push({ name: "SignUpPage" });
        }

        switch (response.authProvider) {
          case "facebook":
          case "google":
            return this.showEmailRegisteredWithSocialAccountModal(response.authProvider);
          case "email":
          default:
            return this.$router.push({ name: "LoginPage" });
        }
      }
    },
    showEmailRegisteredWithSocialAccountModal(authProvider) {
      this.socialAuthProvider = authProvider;
      this.isEmailRegisteredWithSocialAccountModalVisible = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_fonts.scss";

/** @define form-container */
.form-container {
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
  width: 80%;
}

.form-container__field {
  flex: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 0.6em;
}
</style>
