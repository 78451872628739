<template>
  <div class="time-and-correct-answers" :class="'time-and-correct-answers--' + styleModifier">
    <div class="time-and-correct-answers__info" data-test-id="right-answers">
      <span class="info__heading">Aciertos: </span>
      <span class="info__content"> {{ numberOfCorrectAnswers }}/{{ numberOfExercises }}</span>
    </div>

    <div class="time-and-correct-answers__info" data-test-id="total-time">
      <span class="info__heading">Tiempo total: </span>
      <span class="info__content"> {{ totalTime }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeAndCorrectAnswersComponent",
  props: {
    styleModifier: {
      type: String,
      required: true,
      validator: (prop) => ["mobile", "desktop"].includes(prop),
    },
    numberOfCorrectAnswers: {
      type: Number,
      required: true,
    },
    numberOfExercises: { type: Number, required: true },
    totalTime: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define main-content; */

/** @define time-and-correct-answers; */

.time-and-correct-answers {
  width: 100%;

  &--mobile {
    @include laptop {
      display: none;
    }
  }

  &--desktop {
    display: none;
    @include laptop {
      display: flex;
      justify-content: space-around;
      margin-bottom: 2em;
    }
  }
}

.time-and-correct-answers__info {
  display: flex;
  justify-content: space-around;
}

/** @define info; */

.info__heading {
  @extend %font-section-title-2;

  color: $ipn-color;
  @include laptop {
    margin-right: 1em;
  }
}

.info__content {
  @extend %font-body-1--bold;

  display: inline-flex;
  align-items: center;
}
</style>
