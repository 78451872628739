import APIService from "@/services/API";

const GetCoinsBalanceRequest = (request) => request;
// eslint-disable-next-line camelcase
const GetCoinsBalanceResponse = ({ coins_balance = 0, is_coins_bundle_purchase_event_pending = false }) => ({
  coinsBalance: coins_balance,
  isCoinsBundlePurchaseEventPending: is_coins_bundle_purchase_event_pending,
});
class GetCoinsBalanceService extends APIService {
  constructor(studentCoinsServiceRequest) {
    super();
    this.serviceURL = "/student/get-coins-balance/";
    this.response = null;
    this.request = studentCoinsServiceRequest;
  }

  async do() {
    await this.setAuthorizationHeaders();
    this.response = await this.createHttpRequest().get(this.serviceURL, this.request);
    return GetCoinsBalanceResponse(this.response.data);
  }
}

export { GetCoinsBalanceService, GetCoinsBalanceRequest };
